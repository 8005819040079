import { Box, Button, Grid, Paper, Table, TableCell, TableContainer, TableRow, TextField, Toolbar, Typography, useMediaQuery, useTheme, Pagination} from '@mui/material'
import React, {useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useStore } from '../../store/rootStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ViewInvoice from '../../pages/private/purchases/viewInvoice';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface SupplierState {
    name: string;
    phone_number: any;
    company: string;
    address: string;
}

interface SummaryState {
  purchase: 0;
  payment: 0;
  due_payment: 0;
  advance_payment: 0;
  pre_due: 0;
  pre_advance: 0;
  expense: 0;
}

const SupplierDetails = (props:any) => {
    const [searchText, setSearchText] = useState("");
    const [supplier, setSupplier] = useState<SupplierState>(
        {
        name: "",
        phone_number: [],
        company: "",
        address: ""
        }
    );
    const [summary, setSummary] = useState<SummaryState>(
        {
        purchase: 0,
        payment: 0,
        due_payment: 0,
        advance_payment: 0,
        pre_due: 0,
        pre_advance: 0,
        expense: 0
        }
    );
    const {rootStore: {supplierStore, authStore}} = useStore();
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [purchaseId, setPurchaseId] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [page, setPage] = useState(1);
            
    const isMobile = useMediaQuery("(max-width:600px)");

    const theme = useTheme();
        
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Full screen on small devices
    const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md")); // Medium devices

    const itemsPerPage = 5;
            
    const count = Math.ceil(supplierStore.detailsSupplierPaymentRowData.length / itemsPerPage);

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        // console.log(value);
        setPage(value);
    };

    
    const paginatedData = supplierStore.detailsSupplierPaymentRowData.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    
    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await supplierStore.getSupplierDetails(props.id);
            setSupplier(resData.data.supplier);
            setSummary(resData.data.summary);
            console.log(resData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handlePurchaseSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      supplierStore.detailsSupplierPurchaseRowData = supplierStore.supplierDetailsSearchPurchaseData.filter((row)=>{
        return row.invoice_number.toLowerCase().includes(value)
         || row.formatted_date.toLowerCase().includes(value)
         || row.formatted_price.replace(/,/g, '').includes(value)
         || row.formatted_discount.replace(/,/g, '').includes(value)
         || row.formatted_time.toLowerCase().includes(value);
      })
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      supplierStore.detailsSupplierPaymentRowData = supplierStore.supplierDetailsSearchPaymentData.filter((row)=>{
        return row.type.toLowerCase().includes(value)
          || row.amount.replace(/,/g, '').includes(value)
          || row.date.toLowerCase().includes(value)
          || row.time.toLowerCase().includes(value);
      })
    };

    const getButtonColor = (status:any) => {
        switch (status) {
        case "Approve":
            return "success"; // Green button
        case "Pending":
            return "warning"; // Yellow button
        default:
            return "primary"; // Default blue
        }
    };

    const view = (id:any) => {
      // alert(id);
      setPurchaseId(id);
      handleOpenDialog();

    }

    function ccyFormat(num: number) {
        
      const nFormat = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2});
      return nFormat.format(typeof num !== 'undefined'?num:0);
      // return `${num.toFixed(2)}`;
    }

    return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Dialog
    open={dialogOpen}
    onClose={handleDialogClose}
    fullScreen={fullScreen} // Fullscreen on small screens
    PaperProps={{
      style: {
        width: isMedium ? "100vw" : "100vw", // 90% width on medium screens, 80% on larger screens
        maxWidth: "100vw", // Maximum width constraint
        height: isMedium ? "95vh" : "90vh", // 80% height on medium, 60% on larger
        maxHeight: "95vh", // Maximum height constraint
      },
    }}
    >
        <DialogTitle>{t('purchaseDetails')}</DialogTitle>
        <DialogContent
        sx={{
            minHeight: "70vh", // Minimum height for the content area
            padding: "16px", // Optional: Customize padding
        }}
        >
          <ViewInvoice id={purchaseId} type="supplier"/>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDialogClose}>
            {t('cancel')}
        </Button>
        </DialogActions>
    </Dialog>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{mb: 2}}>
      <Grid item xs={12} md={6}>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table sx={{ width: '100%', backgroundColor: '#f0f0f0'  }}>
          <TableRow sx={{ width: '100%'}}>
          <TableCell sx={{padding:'8px 15px', width:'25%' }}>{t('name')}</TableCell>
          <TableCell sx={{padding:'8px 15px', width:'5%' }}>:</TableCell>
          <TableCell sx={{padding:'8px 15px', width:'70%' }}>{supplier.name}</TableCell>
          </TableRow>
          <TableRow>
          <TableCell sx={{padding:'8px 15px' }}>{t('phone')}</TableCell>
          <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
          <TableCell sx={{padding:'8px 15px' }}>
          {supplier.phone_number.map((item:any, index:any) => (
            <span key={index}>
              {item}
              {index < supplier.phone_number.length - 1 && ', '}
            </span>
          ))}
          </TableCell>
          </TableRow>
          <TableRow>
          <TableCell sx={{padding:'8px 15px' }}>{t('company')}</TableCell>
          <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
          <TableCell sx={{padding:'8px 15px' }}>
          {supplier.company}
          </TableCell>
          </TableRow>
          <TableRow>
          <TableCell sx={{padding:'8px 15px' }}>{t('address')}</TableCell>
          <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
          <TableCell sx={{padding:'8px 15px' }}>{supplier.address}</TableCell>
          </TableRow>
          <TableRow sx={{ width: '100%'}}>
            <TableCell sx={{padding:'8px 15px' }}>{t('openingDueEntry')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
            <TableCell sx={{padding:'8px 15px' }}>{ccyFormat(summary.pre_due)}</TableCell>
          </TableRow>
          <TableRow sx={{ width: '100%'}}>
            <TableCell sx={{padding:'8px 15px' }}>{t('openingAdvanceEntry')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
            <TableCell sx={{padding:'8px 15px' }}>{ccyFormat(summary.pre_advance)}</TableCell>
          </TableRow>
          </Table>
      </TableContainer>
      </Grid>
      <Grid item xs={12} md={6}>
      <TableContainer component={Paper} sx={{ width: '100%' }}>
            <Table sx={{ width: '100%', backgroundColor: '#f0f0f0'  }}>
            <TableRow sx={{ width: '100%'}}>
              <TableCell sx={{padding:'8px 15px', width:'25%' }}>{t('totalPurchase')}</TableCell>
              <TableCell sx={{padding:'8px 15px', width:'5%' }}>:</TableCell>
              <TableCell sx={{padding:'8px 15px', width:'70%' }}>{ ccyFormat((summary.purchase+summary.pre_due))}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:'8px 15px' }}>{t('totalPayment')}</TableCell>
              <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
              <TableCell sx={{padding:'8px 15px' }}>
              { ccyFormat(summary.payment+summary.due_payment+summary.advance_payment+summary.pre_advance)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:'8px 15px' }}>{t('totalDue')}</TableCell>
              <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
              <TableCell sx={{padding:'8px 15px' }}>
                { ((summary.purchase+summary.pre_due) - (summary.payment+summary.due_payment+summary.advance_payment+summary.pre_advance)) > 0 ? ccyFormat((summary.purchase+summary.pre_due) - (summary.payment+summary.due_payment+summary.advance_payment+summary.pre_advance)) : ccyFormat(0)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{padding:'8px 15px' }}>{t('totalAdvance')}</TableCell>
              <TableCell sx={{padding:'8px 15px' }}>:</TableCell>
              <TableCell sx={{padding:'8px 15px' }}>
                { ((summary.purchase+summary.pre_due) - (summary.payment+summary.due_payment+summary.advance_payment+summary.pre_advance)) < 0 ? ccyFormat((summary.purchase+summary.pre_due) - (summary.payment+summary.due_payment+summary.advance_payment+summary.pre_advance)) : ccyFormat(0)}
              </TableCell>
            </TableRow>
            </Table>
        </TableContainer>
      </Grid>
    </Grid>
    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{mb: 2}}>
      <Grid item xs={12}>
      <Box
          component="fieldset"
          sx={{
              width: '100%', // Total width including border
              height: 'auto',
              border: "1px solid grey", // Border size
              boxSizing: "border-box", // Include border in the width
              textAlign: "left",
              padding: '10px',
              borderRadius: '5px',
              position:'relative'
          }}
          >
          <Typography component="legend" sx={{ fontSize: ".8rem", color: "#000", position:'absolute', top:'-12px', backgroundColor:'#fff' }}>
              {t('purchaseDetails')}
          </Typography>
          <Toolbar style={{padding: 0, justifyContent:'space-between', alignItems:'center'}}>
              <Grid container alignItems="center" justifyContent={'space-between'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label={t('search')}
                    value={searchText}
                    onChange={handlePurchaseSearch}
                    sx={{
                      margin: 0, // Outer margin
                      '& .MuiInputBase-root': {
                        height: '40px', // Input height
                        padding: '0 0px', // Input padding
                        fontSize: '14px'
                      },
                      '& .MuiInputLabel-root': {
                        padding: '0',
                        marginTop: '-5px',
                        fontSize: '14px',
                      },
                    }}
                  />
                </Grid>
              </Grid>
          </Toolbar>
          <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={supplierStore.detailsSupplierPurchaseRowData}
            columns={
              [
                {
                  field: "id",
                  headerName: "#",
                  filterable: false,
                  width: 20,
                  renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
                  },
                  { field: 'invoice_number', headerName: t('invoiceNumber'), width: 80 },
                  { field: 'formatted_price', headerName: t('price'), width: 100, align:'right', headerAlign:'right' },
                  { field: 'formatted_discount', headerName: t('discount'), width: 100, align:'right', headerAlign:'right' },
                  { field: 'netAmount', headerName: t('netAmount'), width: 100, align:'right', headerAlign:'right' },
                  { field: 'formatted_date', headerName: t('date'), width: 100 },
                  { field: 'formatted_time', headerName: t('time'), width: 150 },
                  {
                      field: 'actions',
                      headerName: t('action'),
                      width: 150,
                      sortable: false,
                      filterable: false,
                      renderCell: (params) => (
                          <>
                              <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>view(params)}><VisibilityIcon/></Button>
                          </>
                      )
                  }
              ]
            }
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 25, 100]}
          />
          </div>
        </Box>
      </Grid>
    </Grid> */}
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{mb: 2}}>
      <Grid item xs={12}>
      <Box
          component="fieldset"
          sx={{
              width: '100%', // Total width including border
              height: 'auto',
              border: "1px solid grey", // Border size
              boxSizing: "border-box", // Include border in the width
              textAlign: "left",
              padding: '10px',
              borderRadius: '5px',
              position:'relative'
          }}
          >
          <Typography component="legend" sx={{ fontSize: ".8rem", color: "#000", position:'absolute', top:'-12px', backgroundColor:'#fff' }}>
              {t('details')}
          </Typography>
          <Toolbar style={{padding: 0, justifyContent:'space-between', alignItems:'center'}}>
              <Grid container alignItems="center" justifyContent={'space-between'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    label={t('search')}
                    value={searchText}
                    onChange={handleSearch}
                    sx={{
                      margin: 0, // Outer margin
                      '& .MuiInputBase-root': {
                        height: '40px', // Input height
                        padding: '0 0px', // Input padding
                        fontSize: '14px'
                      },
                      '& .MuiInputLabel-root': {
                        padding: '0',
                        marginTop: '-5px',
                        fontSize: '14px',
                      },
                    }}
                  />
                </Grid>
              </Grid>
          </Toolbar>
          <div style={{ height: 400, width: '100%' }}>
          {isMobile ? 
          <>
          <Grid container alignItems="baseline" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {
              paginatedData.map((info,index)=>(
                  <Grid item xs={12}>
                      <Paper elevation={5}
                      sx={{
                          p:1
                      }}>
                      <Grid container alignItems="baseline">
                          <Grid container alignItems="baseline">
                              <Grid item xs={6}>
                                  <span style={{fontSize:'16pt'}}>{t('invoiceNumber')} : </span><span style={{fontSize:'12pt'}}>{info.invoice_no}</span>
                              </Grid>
                              <Grid item xs={6}>
                                  <Box sx={{ display: "flex", alignItems:'flex-start', gap:'3px', justifyContent:'flex-end' }}>
                                      <CalendarMonthIcon fontSize="small"/> <span style={{fontSize:'12pt'}}>{info.date}</span>
                                      <AccessTimeIcon fontSize="small"/> <span style={{fontSize:'12pt'}}>{info.time}</span>
                                  </Box>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                  <span style={{fontSize:'16pt'}}>{t('purchasePrice')} : </span>
                                  <span style={{fontSize:'12pt'}}>{info.purchases_price}
                                  {info.is_purchase==='1'
                                  ?
                                  <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>view(info.tableId)}><VisibilityIcon/></Button>
                                  :''}
                                  </span>
                              </Grid>
                              <Grid item xs={6}>
                                  <span style={{fontSize:'16pt'}}>{t('menu.customerPayment')} : </span>
                                  <span style={{fontSize:'12pt'}}>{info.amount} ({info.type})</span>
                              </Grid>
                              <Grid item xs={6}>
                                  <span style={{fontSize:'16pt'}}>{t('createBy')} : </span>
                                  <span style={{fontSize:'12pt'}}>{info.create_by}</span>
                              </Grid>
                          </Grid>
                          
                      </Grid>
                      </Paper>
                  </Grid>
              ))
          }
          </Grid>
          <Pagination 
          count={count} 
          page={page} 
          onChange={handleChange} 
          color="primary" 
          size={isMobile ? "small" : "medium"} 
          sx={{ mt: 2, display: "flex", justifyContent: "center" }} 
          />
          </>
          :
          <DataGrid
            rows={supplierStore.detailsSupplierPaymentRowData}
            columns={
              [
                {
                  field: "id",
                  headerName: "#",
                  filterable: false,
                  width: 20,
                  renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
                  },
                  {field: 'invoice_no', headerName: t('invoiceNumber'), align:'left', width:150},
                  {field: 'purchases_price', headerName: t('purchasePrice'), width:150,
                    renderCell: (params) => (
                      <>
                        {params.value}
                        {params.row.is_purchase==='1'
                        ?
                        <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>view(params.row.tableId)}><VisibilityIcon/></Button>
                        :''}
                      </>
                    )
                  },
                  {field: 'type', headerName: t('supplierPaymenttype'), align:'left', width:150},
                  {field: 'amount', headerName: t('customerPaymentAmount'), align:'left', width:150},
                  {field: 'date', headerName: t('date'), width:150},
                  {field: 'time', headerName: t('time'), width:150},
                  {field: 'create_by', headerName: t('createBy'), width:150},
              ]
            }
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 25, 100]}
          />
          }
          </div>
        </Box>
      </Grid>
    </Grid>
    
    </>
    )
}

export default observer(SupplierDetails);