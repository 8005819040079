import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, Button, MenuItem, Card, CardMedia, IconButton, Typography } from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from "@mui/icons-material/CloudUpload";


const ProductEdit = () => {
    
    const [imageUrl, setImageUrl] = useState<string|null>("../../../default_image.png")
    const [categories, setCategories] = useState<any[]>([])
    const [units, setUnits] = useState<any[]>([]);
    const { rootStore: { productStore } } = useStore();
    const { getData, initCategories, updateData, initUnits } = productStore;
    const { id } = useParams()
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [defaultUnits, setDefaultUnits] = useState<any[]>([]);
    const [preUnits, setPreUnits] = useState<any[]>([]);
    const [hover, setHover] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Product name is required'),
        // category_id: Yup.string(),
        units: Yup.array().min(1, 'At least one option must be selected')
        .required('At least one option must be selected'),
        default_unit: Yup.string().required('You must select an option'),
        image: Yup.mixed().test('fileType', 'Unsupported file format', (value:any) => {
            if (value !== ""){
            const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
            return supportedFormats.includes(value.type);
            }
            return true; // skip this
        }).test('fileSize', 'File size is too large (max: 5000KB)', (value:any) => {
            if (value !== "") {
            return value.size <= 5000000; // 5000KB in bytes
            }
            return true; // skip this
        }),
        variant: Yup.array().of(
            Yup.object().shape({
                inserted_id: Yup.string().required("Variant name is required"),
                name: Yup.string().required("Variant name is required"),
                prices: Yup.array().of(
                    Yup.object().shape({
                        inserted_id: Yup.string().required("Variant name is required"),
                        purchase_price: Yup.string().required("Purchase price required"),
                        sale_price: Yup.string().required("Sale price is required"),
                        alert_limit: Yup.string(),
                    })
                ),
            })
        ),
    })

    type FormValues = Yup.InferType<typeof validationSchema>;

    const { control, register, handleSubmit, setValue, formState: { errors }, reset, setError } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: "",
            // category_id: "",
            units:[],
            default_unit: "",
            image: "",
            variant: [{ inserted_id: "", name: "", prices:[{inserted_id: "", purchase_price: "", sale_price: "", alert_limit: ""}] }],
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "variant", // Name of the field array
    });

    const handleImageClick = () => {
        document.getElementById("image")?.click();
    }

    const onSubmit = async (data:any) => {
        
        data.variant.prices = JSON.stringify(data.variant.prices);
        data.variant = JSON.stringify(data.variant);

        setLoading(true);
        try {
            if(id){
                const formData = new FormData();
                Object.keys(data).map(key => {
                    if(key=="image"){
                    if(data[key] !== "") formData.append(key, data[key]);
                    }else{
                    formData.append(key, data[key]);
                    }
                })
                const resData = await updateData(id, formData)
                // console.log(resData);
                if (resData){
                    reset()
                    navigate('..')
                }
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
                setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
                });
            })
            setLoading(false);
        }
    }
  
    const initCategoriesData = async () => {
        setLoading(true);
        try {
            const resData = await initCategories();
            setCategories(resData.data.categories);

            if(id){
                const resData = await getData(id)
                // console.log(resData.data.product)
                const units = resData.data.product.units;
                const unit_names = resData.data.product.unit_name_array;
                // console.log(units);
                let new_default_object = [];
                for (let i = 0; i < units.length; i++) {
                    // console.log(units[i]);
                    // console.log(unit_names[i]);
                    let unitId = units[i];
                    let new_object = {id:unitId.toString(), text:unit_names[i]};
                    new_default_object.push(new_object);
                }
                // console.log(new_default_object);
                setDefaultUnits(new_default_object);
                let {image, category, ...formData} = resData.data.product
                
                if(image === null){
                    setImageUrl("../../../default_image.png");
                }else{
                    setImageUrl(process.env.REACT_APP_STORAGE_URL +"/"+image)
                }
                
                reset(formData)
            }else{
                navigate(-1)
            }
            setLoading(false);
        } catch (error) {
            // Handle errors, e.g., show an error message
            setLoading(false);
            console.error('Error while fetching data:', error);
        }
    }

    const initUnitsData = async () => {
        try{
            setLoading(true);
            const resData = await initUnits();
            if(resData){
                setLoading(false);
                // console.log(resData);
                setUnits(resData.data.units)
            }
            
        }catch(e: any){
            setLoading(false);
            console.log(e);
        }
    }

    function getLastUrlParam(url:any) {
        const params = url?.split('/').pop() || "null";
        const lastParam = params[params.length - 1]; // Get the last element
        return lastParam;
    }

    useEffect(() => {
        initCategoriesData()
        initUnitsData()
    }, [id])
    

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('products.update')}</h4>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={6} md={5}>
                <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="name"
                    label={t("name")}
                    variant="filled"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            {/* <Grid item xs={6}>
                <Controller
                name="category_id"
                control={control}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    select
                    id="category_id"
                    label={t("category")}
                    variant="filled"
                    error={!!errors.category_id}
                    helperText={errors.category_id?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    >
                    {categories.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.name}
                        </MenuItem>
                    ))}
                    </TextField>
                )}
                />
            </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
                <label htmlFor="">{t('unit')}</label><br/>
                <Controller
                name="units"
                control={control}
                render={({ field }) => (
                    <>
                    {
                        
                        units.map((unit)=>(
                            <label>
                                <input
                                type="checkbox"
                                value={unit.id}
                                onChange={(e) => {
                                    setValue('default_unit','');
                                    const value = e.target.value;
                                    field.onChange(
                                    e.target.checked
                                        ? [...field.value, value]
                                        : field.value.filter((val) => val !== value)
                                    );
                                    var elements = document.getElementsByTagName("input");
                                    
                                    if(e.target.checked){
                                        var defaultObject = {id:value,text:unit.name};
                                        setDefaultUnits([...defaultUnits, defaultObject]);
                                    }else{
                                        setDefaultUnits(items=>items.filter(item=>item.id!==value));
                                    }
                                }}
                                // checked={preUnits.includes(unit.id)}
                                checked={field.value.includes(unit.id.toString())}
                                />
                                {unit.name}
                            </label>
                        ))
                    
                    }
                    </>
                )}
                />
                {errors.units && <p>{errors.units.message}</p>}
                
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <label htmlFor="">{t('defaultUnit')}</label><br/>
                {defaultUnits.map((defaultUnit)=>(
                    <>
                    <label>
                    <input
                        type="radio"
                        value={defaultUnit.id}
                        {...register('default_unit')}
                    />
                    {defaultUnit.text}
                    </label>
                    </>
                ))}
            </Grid>
            <Grid item xs={12}>
                <h4 style={{padding:0,margin:0}}>{t("variantName")}</h4>
            </Grid>
            
            {fields.map((item, index) => (
            <>
            <Grid item xs={12} sm={6} md={5} sx={{marginBottom:2}}>
            <Controller
                name={`variant.${index}.name`}
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="variant_name"
                    label={t("variantName")}
                    variant="filled"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    sx={{
                        margin: 0, // Outer margin
                        '& .MuiInputBase-root': {
                          height: '40px', // Input height
                          padding: '0 0px', // Input padding
                          fontSize: '14px'
                        },
                        '& .MuiInputLabel-root': {
                          padding: '0',
                          marginTop: '-5px',
                          fontSize: '14px',
                        },
                      }}
                    />
                )}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{marginBottom:1}}>
                <Box
                component="fieldset"
                sx={{
                    width: '100%', // Total width including border
                    height: 'auto',
                    border: "1px solid grey", // Border size
                    boxSizing: "border-box", // Include border in the width
                    textAlign: "left",
                    padding: '10px',
                    borderRadius: '5px',
                    position:'relative'
                    }}
                >
                <Typography component="legend" sx={{ fontSize: ".8rem", color: "#000", position:'absolute', top:'-12px', backgroundColor:'#fff' }}>
                    {t('priceDetails')}
                </Typography>
                <PriceFiels
                control={control}
                register={register}
                index={index}
                errors={errors}
                />
                </Box>
            </Grid>
            <Grid item xs={1} md={1} sx={{marginBottom:1}}>
                <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => remove(index)}
                >
                <DeleteIcon/>
                </Button>
            </Grid>
            </>
            ))}
            <Grid item xs={12}>
                <Button
                variant="contained"
                color="primary"
                onClick={() => append({ inserted_id: "0", name: "", prices:[{inserted_id: "0", purchase_price: "", sale_price: "", alert_limit: ""}] })}
                sx={{ marginBottom: 2 }}
                >
                <AddIcon/>
                </Button>
            </Grid>
            <Grid item xs={6}>
                {getLastUrlParam(imageUrl) != null && <Card
                sx={{
                    position: "relative",
                    width: 300,
                    height: 200,
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                {/* CardMedia for background image */}
                <CardMedia
                component="img"
                alt=""
                height="auto"
                image={imageUrl ?? ""}
                sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    transition: "opacity 0.3s ease-in-out",
                    opacity: hover ? 0.7 : 1,
                }}
                />
                {/* Overlay with upload icon */}
                <Box
                    sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: hover ? "rgba(0, 0, 0, 0.5)" : "transparent",
                    transition: "background-color 0.3s ease-in-out",
                    }}
                >
                    {hover && (
                    <IconButton
                        sx={{
                        color: "white",
                        fontSize: "2rem",
                        }}
                        onClick={handleImageClick}
                    >
                        <UploadIcon fontSize="inherit" />
                    </IconButton>
                    )}
                </Box>
            </Card>}
                <Controller
                name="image"
                control={control}
                render={({ field }) => (
                    <TextField
                    fullWidth
                    id="image"
                    type='file'
                    label="Image"
                    variant="filled"
                    style={{display:'none'}}
                    inputProps={{ accept: "image/*" }}
                    focused
                    onChange={(e: any) => {
                        field.onChange(e.target.files[0])
                        e.target.files.length > 0 ? setImageUrl(URL.createObjectURL(e.target.files[0])) : setImageUrl("../../../default_image.png")
                    }}
                    error={!!errors.image}
                    helperText={errors.image?.message}
                    />
                )}
                />
            </Grid>
                </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('update')}
                </Button>
            </form>
        </Box>
        </>
    )
}

const PriceFiels = (props:any) => {
    const [t, i18n] = useTranslation("global");

    const { fields:priceFileds, append, remove} = useFieldArray({
        control:props.control,
        name: `variant.${props.index}.prices`
    });

    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 0.5, sm: 0.5, md: 1 }}>
            {priceFileds.map((priceField, priceIndex)=>(
                <>
                    {/* {priceIndex} */}
                    <Grid item xs={12} sm={6} md={2.6}>
                    <Controller
                    name={`variant.${props.index}.prices.${priceIndex}.purchase_price`}
                    control={props.control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="variant_name"
                        label={t("purchasePrice")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        />
                    )}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.6}>
                    <Controller
                    name={`variant.${props.index}.prices.${priceIndex}.sale_price`}
                    control={props.control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="variant_name"
                        label={t("salePrice")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        />
                    )}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2.6}>
                    <Controller
                    name={`variant.${props.index}.prices.${priceIndex}.alert_limit`}
                    control={props.control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="variant_name"
                        label={t("alert")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 0px', // Input padding
                            fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        />
                    )}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={1}>
                    <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => remove(priceIndex)}
                    >
                    <DeleteIcon/>
                    </Button>
                    </Grid>
                </>
            ))}
            <Grid item xs={12}>
            <Button
                variant="contained"
                onClick={() => append({ inserted_id: "0", purchase_price: "", sale_price: "", alert_limit: "" })}
            >
            <AddIcon/>
            </Button>
            </Grid>
        </Grid>
    )
}

export default observer(ProductEdit);