import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, Button } from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


const CustomerEdit = () => {

    const { rootStore: { customerStore } } = useStore();
    const { getData, updateData } = customerStore;
    const { id } = useParams()
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('formValidation.name')),
        phone: Yup.array().of(
            Yup.object().shape({
                number: Yup.string()
                .matches(/^\d{0}$|^\d{11}$/, "Phone number must be exactly 11 digits or empty")
                .notRequired(),
            })
        ),
        address: Yup.string().required(t('formValidation.address')),
      })
    
    type FormValues = Yup.InferType<typeof validationSchema>;

    const { control, handleSubmit, formState: { errors }, reset, setError } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: "",
            phone: [{ number: "" }],
            address: "",
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "phone", // Name of the field array
    });

    const onSubmit = async (data:any) => {
        data.phone = JSON.stringify(data.phone);
        setLoading(true);
        try {
            if(id){
                const resData = await updateData(id, data)
                if (resData){
                    reset()
                    navigate('..')
                }
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
                setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
                });
            })
            setLoading(false);
        }
    }
  
    const initForm = async () => {
        setLoading(true);
        try {
            if(id){
                const resData = await getData(id)
                // console.log(resData);
                reset(resData.data.customer)
            }else{
                navigate(-1)
            }
            setLoading(false);
        } catch (error) {
            // Handle errors, e.g., show an error message
            setLoading(false);
            console.error('Error while fetching data:', error);
        }
    }
    useEffect(() => {
        initForm()
    }, [id])
    

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('customers.update')}</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="name"
                        label={t('name')}
                        variant="filled"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="address"
                        label={t("address")}
                        variant="filled"
                        error={!!errors.address}
                        helperText={errors.address?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                {fields.map((item, index) => (
                <Box
                    key={item.id}
                    sx={{
                    display: "flex",
                    gap: 2,
                    marginBottom: 2,
                    alignItems: "center",
                    }}
                >
                    <Controller
                    name={`phone.${index}.number`}
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="phone"
                        label={t("phone")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                    <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => remove(index)}
                    >
                    <DeleteIcon/>
                    </Button>
                </Box>
                ))}

                <Button
                variant="contained"
                color="primary"
                onClick={() => append({ number: "" })}
                sx={{ marginBottom: 2 }}
                >
                <AddIcon/>
                </Button>
                </Grid>
                </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('update')}
                </Button>
            </form>
        </Box>
        </>
    )
}

export default observer(CustomerEdit);