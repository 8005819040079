import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, TextField, Toolbar, useMediaQuery, useTheme, Pagination, Paper, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MdiBarcode from '../../../components/icons/MdiBarcode';
import ProductBarcode from '../../../components/product_barcode/productBarcode';

const ProductList = () => {

    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [productCode, setProductCode] = useState(null);
    const [page, setPage] = useState(1);
                
    const isMobile = useMediaQuery("(max-width:600px)");

    const {rootStore: {productStore, authStore}} = useStore();

    const itemsPerPage = 5;
                
    const count = Math.ceil(productStore.rowData.length / itemsPerPage);

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        // console.log(value);
        setPage(value);
    };

    const paginatedData = productStore.rowData.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await productStore.fetchList();
            console.log(resData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const confirmDelete = async(id:any)=>{
      setLoading(true);
      try {
        const resData = await productStore.deleteData(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    const deleteData = (params:any) => {

      productStore.rootStore.dialogStore.openDialog({
        confirmFn: () => confirmDelete(params.id),
        dialogText: t("products.deleteConfirmText")
      })
      // customerStore.deleteDialog(params);
      // setLoading(false);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      productStore.rowData = productStore.searchData.filter((row)=>{
        return row.name.toLowerCase().includes(value)
        //  || row.category.toLowerCase().includes(value)
         || row.units.toLowerCase().includes(value)
         || row.alert_limit.toLowerCase().includes(value)
         || row.purchase_price.toString().includes(value)
         || row.sale_price.toString().includes(value);
      })
    };

    const handleBarcodeDialogClose = () => {
       setDialogOpen(false);
    };

    const handleOpenDialog = (params:any) => {
      // console.log(params.row.product_code);
      setProductCode(params.row.product_code)
      setDialogOpen(true);
    }
    

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Dialog open={dialogOpen} onClose={handleBarcodeDialogClose}>
        <DialogContent>
          <ProductBarcode productCode={productCode}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBarcodeDialogClose}>
              {t('cancel')}
          </Button>
        </DialogActions>
    </Dialog>
    <Box sx={{ height: 400, width: '100%' }}>
      <h4>{t('products.list')}</h4>
       <Button variant="contained" sx={{mb:2}} component={Link} to="create"><PersonAddIcon sx={{mr:1}}/>{t('addNew')}</Button>
       <Toolbar style={{padding:0}}>
        <TextField
          variant="outlined"
          label={t('search')}
          value={searchText}
          onChange={handleSearch}
          sx={{
            margin: 0, // Outer margin
            '& .MuiInputBase-root': {
              height: '40px', // Input height
              padding: '0 0px', // Input padding
              fontSize: '14px'
            },
            '& .MuiInputLabel-root': {
              padding: '0',
              marginTop: '-5px',
              fontSize: '14px',
            },
          }}
        />
      </Toolbar>
      {isMobile ? 
      <>
      <Grid container alignItems="baseline" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {
          paginatedData.map((info,index)=>(
            <Grid item xs={12}>
                <Paper elevation={5}
                sx={{
                    p:1
                }}>
                <Grid container alignItems="baseline">
                    <Grid container alignItems="baseline">
                        <Grid item xs={6}>
                            <span style={{fontSize:'18pt'}}>{info.name}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span style={{fontSize:'16pt'}}>{t('unit')} : </span>
                            <span style={{fontSize:'18pt'}}>{info.units}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span style={{fontSize:'16pt'}}>{t('defaultUnit')} : </span>
                            <span style={{fontSize:'18pt'}}>{info.default_unit}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span style={{fontSize:'16pt'}}>{t('purchasePrice')} : </span>
                            <span style={{fontSize:'12pt', fontWeight:'bold'}}>{info.purchase_price}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span style={{fontSize:'16pt'}}>{t('salePrice')} : </span>
                            <span style={{fontSize:'12pt', fontWeight:'bold'}}>{info.sale_price}</span>
                        </Grid>
                        <Grid item xs={6}>
                            <span style={{fontSize:'16pt'}}>{t('alertLimit')} : </span>
                            <span style={{fontSize:'18pt'}}>{info.alert_limit}</span>
                        </Grid>
                        <Grid item xs={6}>
                            {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'edit/'+info.product_id}><EditIcon/></Button>:""}
                            {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(info)}><DeleteIcon/></Button>:""}
                        </Grid>
                    </Grid>
                </Grid>
                </Paper>
            </Grid>
        ))
      }
      </Grid>
      <Pagination 
      count={count} 
      page={page} 
      onChange={handleChange} 
      color="primary" 
      size={isMobile ? "small" : "medium"} 
      sx={{ mt: 2, display: "flex", justifyContent: "center" }} 
      />
      </>
      :
      <DataGrid
        rows={productStore.rowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 50,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              { field: 'name', headerName: t('productName'), width: 250 },
              // { field: 'category', headerName: t('category'), width: 150 },
              { field: 'units', headerName: t('unit'), width: 150 },
              { field: 'default_unit', headerName: t('defaultUnit'), width: 100 },
              { field: 'purchase_price', headerName: t('purchasePrice'), width: 100 },
              { field: 'sale_price', headerName: t('salePrice'), width: 100 },
              { field: 'alert_limit', headerName: t('alertLimit'), width: 150 },
              {
                  field: 'actions',
                  headerName: t('action'),
                  width: 150,
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => (
                      <>
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'edit/'+params.row.product_id}><EditIcon/></Button>:""}
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(params)}><DeleteIcon/></Button>:""}
                          {/* <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>handleOpenDialog(params)}><MdiBarcode icon="mdi:barcode"/></Button> */}
                      </>
                  )
              }
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
        // checkboxSelection
        // disableRowSelectionOnClick
      />
      }
    </Box>
    </>
  );
}

export default observer(ProductList);
