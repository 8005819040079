import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, FormControlLabel, Checkbox, Button, CardMedia, Card, MenuItem, InputAdornment } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import ServerSideCustomerAutocomplete from "../../../components/ui/ServerSideAutocomplete/ServerSideCustomerAutocomplete";


const CustomerPreAdvanceCreate = () => {

    const { rootStore: { saleStore, customerStore } } = useStore();
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [payment, setPayment] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        customer: Yup.object().shape({
            id: Yup.string().required(t('formValidation.required')),
            label: Yup.string().required(t('formValidation.required')),
        }).required(t('formValidation.required')),
        new_advance: Yup.number().typeError(t('formValidation.numberOnly'))
        .required(t('formValidation.required'))
        .positive('Amount must be positive')
        .min(1, t('formValidation.minimumOne')),
    });

    const hookFormObj = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            customer: {id: "", label: ""},
            new_advance: 0,
        }
    })

    const { control, handleSubmit, formState: {errors}, reset, setValue, getValues, setError } = hookFormObj

    const formatNumberWithCommas = (value:any) => {
        if (!value) return '';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const removeCommas = (value:any) => {
        return value.replace(/,/g, '');
    };
    
    const onSubmit = async (data:any) => {
        setLoading(true);
        try {
            const resData = await customerStore.createPreAdvanceData(data);
            setLoading(false);
            if (resData){
                reset()
                navigate('/app/customers/preAdvance')
            }
          } catch (error:any) {
            setLoading(false);
            console.log('Errors');
            Object.keys(error?.data).map((e:any) => {
              setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
              });
            })
            
          }
    }

    const handleSelectCustomer = (value: any) => {
        console.log("handleSelectCustomer ", value)
        setValue('customer', value);
    };
    
    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        <Box sx={{ width: '100%' }}>
            <h4 style={{marginTop:0}}>{t('customers.newPreAdvance')}</h4>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={8}>
                <Controller
                    name="customer"
                    control={control}
                    render={({ field }) => (
                    <ServerSideCustomerAutocomplete
                        label={t('selectCustomer')}
                        ajaxCallFn={customerStore.getList} 
                        addNewFn={()=>""}
                        onOptionSelect={(option) => {
                            field.onChange(option)
                            handleSelectCustomer(option)
                            if (option?.isNew) {
                                reset({
                                    customer: {id: "", label: ""},
                                })
                                setDialogOpen(true);
                            }

                        }}
                        error={errors.customer?.id ?? errors.customer}
                        field={field}
                    />
                    )}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Controller
                    name="new_advance"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        id="new_advance"
                        label={t('customerNewPreAdvance')}
                        variant="filled"
                        value={formatNumberWithCommas(value)}
                        onChange={(e) => {
                            const rawValue = removeCommas(e.target.value);
                            setValue("new_advance", rawValue, { shouldValidate: true });
                        }}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        onBlur={onBlur}
                        error={!!errors.new_advance}
                        helperText={errors.new_advance?.message}
                        />
                    )}
                    />
                </Grid>
            </Grid>
            </form>
            {/* Add new item form */}
            <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
            </Button>
            <Button sx={{ mt: 2, ml: 2 }} type="button" variant="contained" color="success" onClick={() => handleSubmit(onSubmit)()}>
                {t('save')}
            </Button>
        </Box>
        </>
    )
    
}

export default observer(CustomerPreAdvanceCreate);