import { observer } from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, FormControlLabel, Checkbox, Button, CardMedia, Card, MenuItem, useMediaQuery, useTheme, } from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import ServerSideCustomerAutocomplete from "../../../components/ui/ServerSideAutocomplete/ServerSideCustomerAutocomplete";
import AddNewItemForm from "./addNewItemForm";
import AllItemsList from "./allItemsList";
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SaleCreate = () => {

    const { rootStore: { saleStore, customerStore } } = useStore();
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [payment, setPayment] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    const theme = useTheme();
    
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Full screen on small devices
    const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md")); // Medium devices

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        customer: Yup.object().shape({
            id: Yup.string().required('Customer is required'),
            label: Yup.string().required('Customer is required'),
        }).required('Customer is required'),
        total_purchase: Yup.number(),
        total_payment: Yup.number(),
        total_due: Yup.number(),
        total_advance: Yup.number(),
        date: Yup.date()
        .nullable()
        .typeError('Invalid date format')
        .transform((value, originalValue) => {
            return originalValue === '' ? null : new Date(originalValue);
        }),
    });

    const hookFormObj = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            customer: {id: "", label: ""},
            total_purchase: 0,
            total_payment: 0,
            total_due: 0,
            total_advance: 0,
            date:null
        }
    })

    const { control, handleSubmit, formState: {errors}, reset, setValue, getValues, setError } = hookFormObj
    const [productsErrorMessage, setProductsErrorMessage] = useState<any>(null);

    const customerValidationSchema = Yup.object().shape({
        name: Yup.string().required(t('formValidation.name')),
        address: Yup.string().required(t('formValidation.address')),
        phone: Yup.array().of(
            Yup.object().shape({
                number: Yup.string()
                .matches(/^\d{0}$|^\d{11}$/, "Phone number must be exactly 11 digits or empty")
                .notRequired(),
            })
        ),
        due: Yup.number().typeError(t('formValidation.numberOnly')),
        advance: Yup.number().typeError(t('formValidation.numberOnly')),
      })
    
    type FormValues = Yup.InferType<typeof customerValidationSchema>;
    const { control:customerControl, handleSubmit:customerSubmit, formState: { errors:customerError }, reset:customerReset, setValue:customerSetValue, setError:customerSetError } = useForm<FormValues>({
        resolver: yupResolver(customerValidationSchema),
        defaultValues: {
            name: "",
            address: "",
            phone: [{ number: "" }],
            due: 0,
            advance: 0,
        }
    })

    const { fields, append, remove } = useFieldArray({
        control:customerControl,
        name: "phone", // Name of the field array
    });

    const formatNumberWithCommas = (value:any) => {
        if (!value) return '';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const removeCommas = (value:any) => {
        return value.replace(/,/g, '');
    };

    const onSubmit = async (data:any) => {
        // console.log(data);
        // const formattedDate = `${data.date.getFullYear()}-${String(data.date.getMonth() + 1).padStart(2, '0')}-${String(data.date.getDate()).padStart(2, '0')}`;
        // console.log(formattedDate);
        
        setLoading(true);
        try {
            setProductsErrorMessage(null);
            const resData = await saleStore.createData(data)
            // console.log(resData);
            
            if (resData){
              reset({
                customer: {id: "", label: ""},
              })
              saleStore.setCartItems([]);
              saleStore.cartTotal = 0;
              saleStore.cartSubTotal = 0;
              saleStore.cartDue = 0;
              saleStore.cartDiscount = 0;
              saleStore.cartPayment = 0;
              saleStore.calculateDue = 0;
              saleStore.calculateAdvance = 0;
              saleStore.setcartAditionalInputValues(0,0);
              setDiscount(0);
              setPayment(0);
            //   console.log(resData);
              
              navigate('..')
            }
            
             
           setLoading(false);
          } catch (error:any) {
            console.log('Errors');
            setLoading(false);
            Object.keys(error?.data).map((e:any) => {
              setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
              });
            })
            setProductsErrorMessage("Please select one products");
          }
        
    }

    const onCustomerSubmit = async (data:any) => {
        data.phone = JSON.stringify(data.phone);
        setLoading(true);
        try {
            const resData = await customerStore.createData(data)
            if (resData){
                customerReset()
                console.log(resData);
                const object = {id:resData.data.customer.id, label:resData.data.customer.name, phone_number:resData.data.customer.phone_number, address:resData.data.customer.address}
                
                setValue('customer', object);

                
                const totalPayment = parseFloat(resData.data.customer?.payment) + parseFloat(resData.data.customer?.due_payment) + parseFloat(resData.data.customer?.advance_payment) + parseFloat(resData.data.customer?.pre_advance);
                const totalDue = (parseFloat(resData.data.customer?.purchase) + parseFloat(resData.data.customer?.pre_due)) - Number(totalPayment);
                let advance = 0;
                let due = 0;
                if(totalDue < 0){
                    advance = Math.abs(totalDue);
                }else if(isNaN(totalDue)){
                    due = 0;
                }
                else if(totalDue > 0){
                    due = totalDue;
                }
                else{
                    due = 0;
                }
                // console.log(totalDue);
                // console.log(due);
                // console.log(advance);
                setValue('total_purchase', formatNumberWithCommas(resData.data.customer?.purchase));  
                setValue('total_payment', isNaN(totalPayment)?0:formatNumberWithCommas(totalPayment));
                setValue('total_due', formatNumberWithCommas(due));  
                setValue('total_advance', formatNumberWithCommas(advance)); 
                saleStore.customerDue = due; 
                saleStore.customerAdvance = advance;
                
                if(saleStore.customerAdvance > 0){
                    let calDue = saleStore.subTotal(saleStore.cartItems) - saleStore.customerAdvance;
                    if(calDue > 0){
                        saleStore.calculateDue = calDue;
                    }else{
                        saleStore.calculateAdvance = Math.abs(calDue);
                    }
                }else{
                    let calDue = saleStore.subTotal(saleStore.cartItems) + saleStore.customerDue;
                    saleStore.calculateDue = calDue;
                    saleStore.calculateAdvance = advance;
                }

                handleNewCustomerDialogClose();
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
            setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
            });
            })
            setLoading(false);
        }
    }
    

    const handleSelectCustomer = (value: any) => {
        console.log("handleSelectCustomer ", value)
        const totalPayment = parseFloat(value?.payment) + parseFloat(value?.due_payment) + parseFloat(value?.advance_payment) + parseFloat(value?.pre_advance);
        const totalDue = (parseFloat(value?.purchase) + parseFloat(value?.pre_due)) - Number(totalPayment);
        let advance = 0;
        let due = 0;
        if(totalDue < 0){
            advance = Math.abs(totalDue);
        }else if(isNaN(totalDue)){
            due = 0;
        }
        else if(totalDue > 0){
            due = totalDue;
        }
        else{
            due = 0;
        }
        // console.log(totalDue);
        // console.log(due);
        // console.log(advance);
        const totalPurchase = parseFloat(value?.purchase) + parseFloat(value?.pre_due);
        setValue('customer', value);
        setValue('total_purchase', totalPurchase);  
        setValue('total_payment', isNaN(totalPayment)?0:totalPayment);
        setValue('total_due', due);  
        setValue('total_advance', advance); 
        saleStore.customerDue = due; 
        saleStore.customerAdvance = advance;
        
        if(saleStore.customerAdvance > 0){
            let calDue = saleStore.subTotal(saleStore.cartItems) - saleStore.customerAdvance;
            if(calDue > 0){
                saleStore.calculateDue = calDue;
            }else{
                saleStore.calculateAdvance = Math.abs(calDue);
            }
        }else{
            let calDue = saleStore.subTotal(saleStore.cartItems) + saleStore.customerDue;
            saleStore.calculateDue = calDue;
            saleStore.calculateAdvance = advance;
        }
    };
    
    const handleNewCustomerDialogClose = () => {
        setDialogOpen(false);
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    }

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        <Dialog
        open={dialogOpen}
        onClose={handleNewCustomerDialogClose}
        fullScreen={fullScreen} // Fullscreen on small screens
        PaperProps={{
          style: {
            width: isMedium ? "90vw" : "85vw", // 90% width on medium screens, 80% on larger screens
            maxWidth: "95vw", // Maximum width constraint
            height: isMedium ? "95vh" : "90vh", // 80% height on medium, 60% on larger
            maxHeight: "95vh", // Maximum height constraint
          },
        }}
        >
            <DialogTitle>{t('customers.create')}</DialogTitle>
            <form onSubmit={customerSubmit(onCustomerSubmit)}>
            <DialogContent
            sx={{
                minHeight: "70vh", // Minimum height for the content area
                padding: "16px", // Optional: Customize padding
            }}
            >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={6}>
                <Controller
                name="name"
                control={customerControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="name"
                    label={t('name')}
                    variant="filled"
                    error={!!customerError.name}
                    helperText={customerError.name?.message}
                    autoComplete="off"
                    size="small"
                    />
                )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Controller
                name="address"
                control={customerControl}
                render={({ field }) => (
                    <TextField
                    {...field}
                    fullWidth
                    id="address"
                    label={t("address")}
                    variant="filled"
                    error={!!customerError.address}
                    helperText={customerError.address?.message}
                    autoComplete="off"
                    size="small"
                    />
                )}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {fields.map((item, index) => (
                <Box
                    key={item.id}
                    sx={{
                    display: "flex",
                    gap: 2,
                    marginBottom: 2,
                    alignItems: "center",
                    }}
                >
                    <Controller
                    name={`phone.${index}.number`}
                    control={customerControl}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="phone"
                        label={t("phone")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                    <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => remove(index)}
                    >
                    <DeleteIcon/>
                    </Button>
                </Box>
                ))}

                <Button
                variant="contained"
                color="primary"
                onClick={() => append({ number: "" })}
                sx={{ marginBottom: 2 }}
                >
                <AddIcon/>
                </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Controller
                    name="due"
                    control={customerControl}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        id="due"
                        label={t('due_save')}
                        variant="filled"
                        error={!!customerError.due}
                        helperText={customerError.due?.message}
                        autoComplete="off"
                        onBlur={onBlur}
                        onFocus={(e) => e.target.select()}
                        value={formatNumberWithCommas(value)}
                        onChange={(e) => {
                            const rawValue = removeCommas(e.target.value);
                            customerSetValue("due", rawValue, { shouldValidate: true });
                        }}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Controller
                    name="advance"
                    control={customerControl}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        id="advance"
                        label={t('advance_save')}
                        variant="filled"
                        error={!!customerError.advance}
                        helperText={customerError.advance?.message}
                        autoComplete="off"
                        onBlur={onBlur}
                        onFocus={(e) => e.target.select()}
                        value={formatNumberWithCommas(value)}
                        onChange={(e) => {
                            const rawValue = removeCommas(e.target.value);
                            customerSetValue("advance", rawValue, { shouldValidate: true });
                        }}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleNewCustomerDialogClose}>
                {t('cancel')}
            </Button>
            <Button color="primary" type="submit">
                {t('save')}
            </Button>
            </DialogActions>
            </form>
        </Dialog>
        <Box sx={{ width: '100%' }}>
            <h4 style={{marginTop:0}}>{t('sales.create')}</h4>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={4.7}>
                <Controller
                    name="customer"
                    control={control}
                    render={({ field }) => (
                    <ServerSideCustomerAutocomplete
                        label={t('selectCustomer')}
                        ajaxCallFn={customerStore.getList} 
                        addNewFn={handleOpenDialog}
                        onOptionSelect={(option) => {
                            field.onChange(option)
                            handleSelectCustomer(option)
                            if (option?.isNew) {
                                reset({
                                    customer: {id: "", label: ""},
                                })
                                setDialogOpen(true);
                            }

                        }}
                        error={errors.customer?.id ?? errors.customer}
                        field={field}
                    />
                    )}
                />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_purchase"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        
                        fullWidth
                        id="total_purchase"
                        label={t('totalPurchase')}
                        variant="filled"
                        value={formatNumberWithCommas(value)}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_purchase}
                        helperText={errors.total_purchase?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_payment"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        
                        fullWidth
                        id="total_payment"
                        label={t('totalPayment')}
                        variant="filled"
                        value={formatNumberWithCommas(value)}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_payment}
                        helperText={errors.total_payment?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_due"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        
                        fullWidth
                        id="total_due"
                        label={t('totalDue')}
                        variant="filled"
                        value={formatNumberWithCommas(value)}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_due}
                        helperText={errors.total_due?.message}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={1.8}>
                <Controller
                    name="total_advance"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                        InputProps={{
                            readOnly: true,
                            disabled: true,
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        
                        fullWidth
                        id="total_advance"
                        label={t('totalAdvance')}
                        variant="filled"
                        value={formatNumberWithCommas(value)}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                            height: '40px', // Input height
                            padding: '0 14px', // Input padding
                            },
                            '& .MuiInputLabel-root': {
                            padding: '0',
                            marginTop: '-5px',
                            fontSize: '14px',
                            },
                        }}
                        error={!!errors.total_advance}
                        helperText={errors.total_advance?.message}
                        />
                    )}
                    />
                </Grid>
            </Grid>
            </form>
            {/* Add new item form */}
            <AddNewItemForm/>
            {productsErrorMessage ?  <Box sx={{ color: 'error.main', my: 2 }}>{productsErrorMessage}</Box>: ""}
            <AllItemsList editMode={true} />
            <Grid container justifyContent={'flex-end'}>
                <Grid item xs={3}>
                    <Controller
                        control={control}
                        name="date"
                        render={({ field }) => (
                        <DatePicker
                            placeholderText="Due payment date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            dateFormat="dd-MM-yyyy"
                            customInput={
                            <TextField
                                variant="outlined"
                                fullWidth
                                sx={{
                                margin: 0, // Outer margin
                                '& .MuiInputBase-root': {
                                    height: '40px', // Input height
                                    padding: '0 0px', // Input padding
                                    fontSize: '14px',
                                    width:'100%'
                                },
                                }}
                            />
                            }
                        />
                        )}
                    />
                    {errors.date && <p style={{ color: 'red' }}>{errors.date.message}</p>}
                </Grid>
            </Grid>
            <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
            </Button>
            <Button sx={{ mt: 2, ml: 2 }} type="button" variant="contained" color="success" onClick={() => handleSubmit(onSubmit)()}>
                {t('save')}
            </Button>
        </Box>
        </>
    )
    
}

export default observer(SaleCreate);