import { Box, Button, Toolbar, useMediaQuery, useTheme, Pagination, Paper } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useStore } from "../../store/rootStore";
import { Grid, Container, TextField } from '@mui/material';
import InfoCard from "../../components/card/InfoCard";
import LinearProgress from '@mui/material/LinearProgress';
import DailyBarChart from "../../components/Charts/dailyBarChart";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DatePicker from 'react-datepicker';
import CachedIcon from '@mui/icons-material/Cached';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const Dashboard = () => {

    const [t, i18n] = useTranslation("global");
    const [searchText, setSearchText] = useState("");
    const { rootStore: { authStore, dashboardStore } } = useStore();
    const [loading, setLoading] = useState(false);
    const [dailyBarCharts, setDailyBarCharts] = useState([]);
    const [updateId, setUpdateId] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [message, setMessage] = useState('');
    
    const [page, setPage] = useState(1);

    const isMobile = useMediaQuery("(max-width:600px)");

    const theme = useTheme();
    
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Full screen on small devices
    const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md")); // Medium devices

    const itemsPerPage = 5;

    const count = Math.ceil(dashboardStore.customerDueReportRowData.length / itemsPerPage);

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        // console.log(value);
        setPage(value);
    };

    
    const paginatedData = dashboardStore.customerDueReportRowData.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const initTable = async () => {
        setLoading(true);
        try {
            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            const resData = await dashboardStore.fetchDashboardDetails(formattedDate);
            setDailyBarCharts(resData.data.dailyBarCarts);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    
    const initCustomerDueTable = async () => {
        setLoading(true);
        try {
            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            const resData = await dashboardStore.fetchCustomerDueReportList(formattedDate);
            console.log(resData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const handleUpdateDialogClose = () => {
          
        setDialogOpen(false);
    };

    const handleUpdateOpenDialog = () => {
        
        setDialogOpen(true);
    }

    const validationSchema = Yup.object().shape({
      date: Yup.date()
      .nullable()
      .required('Date is required')
      .typeError('Invalid date format')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : new Date(originalValue);
      }),
    })

    const { control, handleSubmit, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
          date: new Date()
        }
    })

    const onSubmit = async (data:any) => {
        setLoading(true);
        try {
            const date = new Date(data.date);
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            // console.log(formattedDate);
            const resData = await dashboardStore.fetchCustomerDueReportList(formattedDate);
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
            setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
            });
            })
            setLoading(false);
        }
      }

    const updateValidationSchema = Yup.object().shape({
        id: Yup.string(),
        date: Yup.date()
        .nullable()
        .required('Date is required')
        .typeError('Invalid date format')
        .transform((value, originalValue) => {
          return originalValue === '' ? null : new Date(originalValue);
        }),
    })

    const { control:updateControl, handleSubmit:updateHandleSubmit, formState: { errors:updateErrors }, reset:updateReset, setValue:updateSetValue, setError:updateSetError } = useForm({
        resolver: yupResolver(updateValidationSchema),
        defaultValues: {
            id: "",
            date: new Date()
        }
    })


    const editData = async(params:any) => {
        updateReset({date:new Date()});
        setUpdateId(params.id);
        handleUpdateOpenDialog();
        setLoading(true);
        try {
            updateSetValue('id', params.id);
            setLoading(false);
        } catch (error) {
            // Handle errors, e.g., show an error message
            setLoading(false);
            console.error('Error while fetching data:', error);
        }
  
        console.log(params.id);
  
    }
    const editData2 = async(id:any) => {
        updateReset({date:new Date()});
        setUpdateId(id);
        handleUpdateOpenDialog();
        setLoading(true);
        try {
            updateSetValue('id', id);
            setLoading(false);
        } catch (error) {
            // Handle errors, e.g., show an error message
            setLoading(false);
            console.error('Error while fetching data:', error);
        }
  
        console.log(id);
  
    }

    const onUpdateCustomerDueSubmit = async (data:any) => {
      setLoading(true);
      try {
          if(updateId !== null){
              const resData = await dashboardStore.updateDueDate(data)
              // console.log(resData);
              updateReset({id:'',date:new Date()});
              handleUpdateDialogClose();
              initCustomerDueTable();
          }
          setLoading(false);
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          updateSetError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setSearchText(event.target.value);
  
        dashboardStore.customerDueReportRowData = dashboardStore.searchCustomerDueReportData.filter((row)=>{
          return row.name.toLowerCase().includes(value)
           || row.phone_number.toLowerCase().includes(value)
           || row.address.toLowerCase().includes(value)
           || (row.purchase||"").replace(/,/g, '').includes(value)
           || (row.payment||"").replace(/,/g, '').includes(value)
           || (row.due_payment||"").replace(/,/g, '').includes(value)
           || (row.advance_payment||"").replace(/,/g, '').includes(value)
           || (row.totalDue||"").replace(/,/g, '').includes(value)
           || row.lastPaymentDate.toLowerCase().includes(value);
        })
      };

    const refresh = async() => {
        setLoading(true);
        try {
            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            const resData = await dashboardStore.fetchCustomerDueReportList(formattedDate);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    useEffect(()=>{
        initTable();
        initCustomerDueTable();
        // console.log(authStore.role)
    },[])

    return (
        <>
            {loading ? 
            <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
                <LinearProgress color="secondary"/>
            </Box>
            : ''
            }
            <Dialog open={dialogOpen} onClose={handleUpdateDialogClose}
                fullScreen={fullScreen} // Fullscreen on small screens
                PaperProps={{
                  style: {
                    width: isMedium ? "100vw" : "80vw", // 90% width on medium screens, 80% on larger screens
                    maxWidth: "100vw", // Maximum width constraint
                    height: isMedium ? "1000vh" : "80vh", // 80% height on medium, 60% on larger
                    maxHeight: "100vh", // Maximum height constraint
                  },
                }}
                // sx={{
                //     '& .MuiDialog-paper': {
                //     width: '80%',            // Custom width
                //     maxWidth: '500px',       // Custom max-width
                //     height:'70%'
                //     },
                // }}
                >
                <DialogTitle>{t('updateLastPaymentDate')}</DialogTitle>
                    <form onSubmit={updateHandleSubmit(onUpdateCustomerDueSubmit)}>
                    <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }}>
                    <Grid item xs={12}>
                    <Controller
                        control={updateControl}
                        name="date"
                        render={({ field }) => (
                            <DatePicker
                            placeholderText="Select a date"
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                            dateFormat="dd-MM-yyyy"
                            customInput={
                                <TextField
                                variant="outlined"
                                fullWidth
                                sx={{
                                    margin: 0, // Outer margin
                                    '& .MuiInputBase-root': {
                                    height: '40px', // Input height
                                    padding: '0 0px', // Input padding
                                    fontSize: '14px',
                                    width:'100%'
                                    },
                                }}
                                />
                            }
                            />
                        )}
                    />
                        {updateErrors.date && <p style={{ color: 'red' }}>{updateErrors.date.message}</p>}
                    </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions style={{position:'absolute',width:'100%',bottom:0}}>
                    <Button onClick={handleUpdateDialogClose}>
                        {t('cancel')}
                    </Button>
                    <Button color="primary" type="submit">
                        {t('update')}
                    </Button>
                    </DialogActions>
                    </form>
                </Dialog>
            <h2>{t('dashboard.title')}</h2>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 0.5, sm: 0.5, md: 1 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todayPurchase')} amount={dashboardStore.dashboardData.purchase} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todaySales')} amount={dashboardStore.dashboardData.sales} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todayCustomerPayment')} amount={dashboardStore.dashboardData.customerPayment} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todaySupplierPayment')} amount={dashboardStore.dashboardData.supplierPayment} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todayExtraIncome')} amount={dashboardStore.dashboardData.extraIncome} backgroundColor="#d9edf3" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InfoCard title={t('todayExtraExpense')} amount={dashboardStore.dashboardData.extraIncome} backgroundColor="#d9edf3" />
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Container sx={{mt:2,p:0}}>
                    <Grid item xs={12} md={12}>
                        <DailyBarChart data={dailyBarCharts} />
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12} md={12} sx={{mt:2}}>
                <h3 style={{padding:0,margin:0}}>
                    <span style={{borderBottom:'2px solid #ccc'}}>{t('todayDuePaymentInfo')}</span>
                </h3>
            </Grid>
            <Grid container justifyContent="center">
                <Container sx={{mt:2,p:0, height:400}}>
                    <Grid item xs={12} md={12}>
                        <Toolbar style={{padding: 0, justifyContent:'space-between', alignItems:'center'}}>
                            <TextField
                            variant="outlined"
                            label={t('search')}
                            value={searchText}
                            onChange={handleSearch}
                            sx={{
                                margin: 0, // Outer margin
                                '& .MuiInputBase-root': {
                                height: '40px', // Input height
                                padding: '0 0px', // Input padding
                                fontSize: '14px'
                                },
                                '& .MuiInputLabel-root': {
                                padding: '0',
                                marginTop: '-5px',
                                fontSize: '14px',
                                },
                            }}
                            />
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Grid container alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                    <Controller
                                    control={control}
                                    name="date"
                                    render={({ field }) => (
                                        <DatePicker
                                        placeholderText="Select a date"
                                        onChange={(date) => field.onChange(date)}
                                        selected={field.value}
                                        dateFormat="dd-MM-yyyy"
                                        customInput={
                                            <TextField
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                                margin: 0, // Outer margin
                                                '& .MuiInputBase-root': {
                                                height: '40px', // Input height
                                                padding: '0 0px', // Input padding
                                                fontSize: '14px'
                                                },
                                            }}
                                            />
                                        }
                                        />
                                    )}
                                    />
                                    {errors.date && <p style={{ color: 'red' }}>{errors.date.message}</p>}
                                    </Grid>
                                    <Grid item xs={1}>
                                    <Button sx={{ ml: .01, mr:0 }} type="submit" variant="contained" color="success">
                                        {t('search')}
                                    </Button>
                                    </Grid>
                                </Grid>
                            </form>
                            <Button sx={{ ml: .01, mr:0 }} type="button" onClick={refresh} variant="contained" color="success">
                            <CachedIcon/>
                            </Button>
                        </Toolbar>
                        {isMobile ? 
                        <>
                        <Grid container alignItems="baseline" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {
                            paginatedData.map((customer,index)=>(
                                <Grid item xs={12}>
                                    <Paper elevation={5}
                                    sx={{
                                        p:1
                                    }}>
                                    <Grid container alignItems="baseline">
                                        <Grid container alignItems="baseline"sx={{ borderBottom: "2px solid #ccc" }}>
                                            <Grid item xs={6}>
                                                <span style={{fontSize:'18pt'}}>{customer.name}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{ display: "flex", alignItems:'flex-start', gap:'3px', justifyContent:'flex-end' }}>
                                                    <CalendarMonthIcon fontSize="small"/> <span style={{fontSize:'12pt', fontWeight:'bold'}}>{customer.lastPaymentDate}</span>
                                                    {authStore.role==='Admin' ? <Button style={{maxHeight:'20px', minWidth:'40px'}} onClick={()=>editData2(customer.id)}><EditIcon fontSize="small"/></Button>:""}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span style={{fontSize:'12pt'}}>{customer.phone_number}</span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span style={{fontSize:'15pt'}}>{customer.address}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="baseline">
                                            <Grid item xs={6}>
                                                <span style={{fontSize:'15pt'}}>{t('purchasesAmount')}</span> : {customer.purchase}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span style={{fontSize:'15pt'}}>{t('cashPayment')}</span> : {customer.payment}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span style={{fontSize:'15pt'}}>{t('duePayment')}</span> : {customer.due_payment}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span style={{fontSize:'15pt'}}>{t('advancePayment')}</span> : {customer.advance_payment}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span style={{fontSize:'15pt', fontWeight:'bold'}}>{t('totalDue')} : {customer.totalDue}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </Paper>
                                </Grid>
                            ))
                        }
                        </Grid>
                        <Pagination 
                        count={count} 
                        page={page} 
                        onChange={handleChange} 
                        color="primary" 
                        size={isMobile ? "small" : "medium"} 
                        sx={{ mt: 2, display: "flex", justifyContent: "center" }} 
                        />
                        </>
                        :
                        <DataGrid
                            rows={dashboardStore.customerDueReportRowData}
                            columns={
                            [
                                {
                                field: "id",
                                headerName: "#",
                                filterable: false,
                                width: 50,
                                renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
                                },
                                {field: 'name', headerName: t('name'), width:120},
                                {
                                    field: 'phone_number', headerName: t('phone'), width:120
                                },
                                {field: 'address', headerName: t('address')},
                                {field: 'purchase', headerName: t('purchasesAmount'), align:'right'},
                                {field: 'payment', headerName: t('cashPayment'), align:'right'},
                                {field: 'due_payment', headerName: t('duePayment'), align:'right'},
                                {field: 'advance_payment', headerName: t('advancePayment'), align:'right'},
                                {field: 'totalDue', headerName: t('totalDue'), align:'right'},
                                {field: 'lastPaymentDate', headerName: t('lastPaymentDate'), align:'right'},
                                {
                                    field: 'actions',
                                    headerName: t('action'),
                                    width: 70,
                                    sortable: false,
                                    filterable: false,
                                    renderCell: (params) => (
                                        <>
                                            {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>editData(params)}><EditIcon/></Button>:""}
                                        </>
                                    )
                                }
                            ]
                            }
                            autoHeight
                            sx={{
                                "& .MuiDataGrid-cell": {
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                lineHeight: "1.5", // Adjust spacing if needed
                                },
                            }}
                            initialState={{
                            pagination: {
                                paginationModel: {
                                pageSize: 5,
                                },
                            },
                            }}
                            pageSizeOptions={[5, 25, 100]}
                        />
                        }
                    </Grid>
                </Container>
            </Grid>
        </>
    )
}

export default observer(Dashboard);