import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { observer } from 'mobx-react-lite';

// Register necessary components in Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface OverviewData {
  date: string;
  sales: number;
  purchase: number;
  customerPayment: number;
  supplierPayment: number;
  extraIncome: number;
  extraExpense: number;
}

interface BarChartProps {
  data: OverviewData[];
}

const DailyBarChart: React.FC<BarChartProps> = ({ data }) => {

  const [t, i18n] = useTranslation("global");
  
  // Prepare data for the Bar chart
  const chartData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: 'Purchases',
        data: data.map((item) => item.purchase),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        categoryPercentage: 1,
        
      },
      {
        label: 'Sales',
        data: data.map((item) => item.sales),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        categoryPercentage: 1,
      },
      {
        label: 'Customer Payment',
        data: data.map((item) => item.customerPayment),
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
        categoryPercentage: 1,
      },
      {
        label: 'Supplier Payment',
        data: data.map((item) => item.supplierPayment),
        backgroundColor: 'rgba(236, 56, 11, 0.6)',
        categoryPercentage: 1,
      },
      {
        label: 'Extra Income',
        data: data.map((item) => item.extraIncome),
        backgroundColor: 'rgba(144, 8, 137, 0.6)',
        categoryPercentage: 1,
      },
      {
        label: 'Extra Expense',
        data: data.map((item) => item.extraExpense),
        backgroundColor: 'rgba(144, 24, 8, 0.6)',
        categoryPercentage: 1,
      },
    ],
  };

//   const options = {
//     responsive: true,
//     plugins: {
//       legend: {
//         position: 'top' as const,
//       },
//       title: {
//         display: true,
//         text: 'Daily Overview of Purchases, Sales, Customer Payment, Supplier Payment Extra Income and Extra Expense',
//       },
//     },
//   };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Daily Overview of Purchases, Sales, Customer Payment, Supplier Payment Extra Income and Extra Expense',
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false, // Prevents skipping of ticks on the x-axis
        },
      },
    },
  };

  return (
    <Card>
      <CardContent>
        <Bar data={chartData} options={options} height={400}/>
      </CardContent>
    </Card>
  );
};

export default observer(DailyBarChart);
