import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import ViewInvoice from "./viewInvoice";


const PurchaseView = () =>{
  
  const { id } = useParams()

  return(
    <ViewInvoice id={id} type="purchase"/>
  )
}

export default observer(PurchaseView);
