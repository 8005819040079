import { Button, Grid, TextField, dividerClasses, MenuItem, Box, Autocomplete } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ServerSideAutocomplete from '../../../components/ui/ServerSideAutocomplete/ServerSideAutocomplete'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const AddNewItemForm:React.FC<any> = () => {
  const [units, setUnits] = useState<any[]>([]);
  const [defaultUnits, setDefaultUnits] = useState(null);
  const [unitId, setUnitId] = useState('');
  const qtyTextFieldRef = useRef<HTMLInputElement | null>(null);
  const priceTextFieldRef = useRef<HTMLInputElement | null>(null);
  const barcodeTextFieldRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState<any[]>([]);
  const [isDisabled, setDisabled] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  
  const [textValue, setTextValue] = useState(null); // Selected value
  const [inputPriceValue, setPriceInputValue] = useState(""); // Input text value
  
  // const [unitName, setUnitName] = useState('');
  const [t, i18n] = useTranslation("global");

  const validationSchema = Yup.object().shape({
    product: Yup.object().shape({
      id: Yup.string().required(t('formValidation.required')),
      label: Yup.string().required(t('formValidation.required')),
    }).required(t('formValidation.required')),
    price: Yup.object().shape({
      id: Yup.string().required(t('formValidation.required')),
      label: Yup.string().required(t('formValidation.required')),
    }).required(t('formValidation.required')),
    // quantity: Yup.number()
    // .when('stock', (stock:any, schema) => 
    //   schema.max(stock, t('formValidation.outOfStock'))
    // )
    // .typeError(t('formValidation.numberOnly')).required(t('formValidation.required')).min(1, t('formValidation.minimumOne')),
    unit_id: Yup.string().required(t('formValidation.required')),
    discount: Yup.number().typeError(t('formValidation.numberOnly')).required(t('formValidation.required')).min(0, t('formValidation.minimumOne')).max(100, t('formValidation.maximumHundred')),
    withOutDiscount: Yup.number(),
    total: Yup.number(),
    edit: Yup.number(),
    product_data: Yup.object(),
    itemIndex: Yup.number(),
    unitName: Yup.string(),
    defaultUnitName: Yup.string(),
    selected_unit: Yup.string(),
    units: Yup.array(),
    product_units: Yup.array().of(
      Yup.object().shape({
        unit_id: Yup.number(),
        default: Yup.number(),
        name: Yup.string(),
        calculate_value: Yup.string(),
        operator: Yup.string(),
        quantity: Yup.string(),
        // quantity: Yup
        //   .number()
        //   .typeError("Quantity must be a number")
        //   .positive("Quantity must be positive")
        //   .required("Quantity is required"),
      })
    ).test(
      "at-least-one",
      "At least one unit quantity is required",
      (value) => value && value.some((item) => Number(item.quantity) > 0)
    ),
  })

  const { rootStore: { purchaseStore, productStore} } = useStore();
  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      product: {id: "", label: ""},
      price: {id: "", label: ""},
      // quantity: 1,
      unit_id: "",
      discount: 0,
      withOutDiscount: 0,
      total: 0,
      edit: 0,
      product_data: {},
      itemIndex: -1,
      unitName:"",
      defaultUnitName:"",
      selected_unit:"",
      units: [],
      product_units: [
        { unit_id: 0, default: 0, name: "", calculate_value: "", operator:"", quantity: "" },
      ],
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "product_units",
  });

  const onSubmit = async (data:any) => {
    // console.log(data);
    
    purchaseStore.addToCart(data);
    setUnits([]);
    setPrices([]);
    reset({
      product: {id: "", label: ""},
      price: {id: "", label: ""},
      // quantity: 1,
      unit_id: "",
      discount: 0,
      withOutDiscount: 0,
      total: 0,
      edit: 0,
      product_data: {},
      itemIndex: -1,
      unitName:"",
      defaultUnitName:"",
      selected_unit:"",
      units: [],
      product_units: [
        { unit_id: 0, default: 0, name: "", calculate_value: "", operator:"", quantity: "" },
      ],
    })

    if (qtyTextFieldRef.current) {
      qtyTextFieldRef.current.blur();
    }

    // if (barcodeTextFieldRef.current) {
    //   barcodeTextFieldRef.current.focus();
    // }
    
  }


  const handleSelectProduct = (value: any) => {
    console.log("handleSelectProduct ", value)
    
    setUnits(value?.units);
    setValue('product', value);
    setPriceValue('variant_id', value.id);
    setPrices(value?.prices); 
    setValue('withOutDiscount', value?.price); 
    setValue('total', value?.price); 
    // setValue('quantity', 1);
    setValue('unit_id', value?.default_unit);
    setUnitId(value?.default_unit);
    setValue('edit', 0);
    setValue('itemIndex', -1);
    setValue('product_data', value);
    setValue('unitName', value?.default_unit_name);
    setValue('defaultUnitName', value?.default_unit_name);
    setValue('selected_unit', value?.default_unit);
    setValue('units', value?.units);
    setValue('product_units', value?.units);
    setPriceInputValue("");
    
    if (priceTextFieldRef.current) {
      priceTextFieldRef.current.focus();
      priceTextFieldRef.current.select();
    }
    
  };
  
  
  const calculateUnitPrice = () => {

    const u = units.filter((item)=>item.id===unitId);
    const calculate_value = u[0].calculate_value;
    const calculate_operator = u[0].operator;
    
    const default_unit = getValues('unit_id')?? 0
    const original = Number(getValues('price').label);
    const discount = getValues('discount') ?? 0

    // console.log(unitId+'-'+value);
    
    // console.log(getValues('price').label);
    // console.log(getValues('product_units'));
    
    let totalWithOutDiscountPrice = 0;
    let totalPrice = 0;
    getValues('product_units')?.map((item) => {
      // console.log(item);
      // console.log(item.unit_id+'='+default_unit);
      if(Number(item.unit_id) === Number(default_unit)){
        const finalPrice = original - (original *  discount / 100);
        const finalPrice2 = original;
        totalPrice += finalPrice*Number(item.quantity);
        totalWithOutDiscountPrice += finalPrice2*Number(item.quantity);
      }else if(item.operator === '*'){
        const finalPrice = (original*calculate_value) - (original *  discount / 100);
        const finalPrice2 = original*calculate_value;
        totalPrice += finalPrice*Number(item.quantity);
        totalWithOutDiscountPrice += finalPrice2*Number(item.quantity);
      }
      else{
        const finalPrice = (original/calculate_value) - ((original/calculate_value) *  discount / 100);
        const finalPrice2 = original/calculate_value;
        totalPrice += finalPrice*Number(item.quantity);
        totalWithOutDiscountPrice += finalPrice2*Number(item.quantity);
      }
    })
    setValue('withOutDiscount', totalWithOutDiscountPrice);
    setValue('total', totalPrice);
  }

  const handleKeyDown = async(event:any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // console.log(event.target.value);
      setLoading(true);
      const data = {search:event.target.value};
      try {
          const resData = await productStore.getListWithBarcode(data);
          const value = resData[0];
          console.log(value);
          setUnits(value?.units);
          setValue('product', value); 
          setValue('price', value?.price); 
          setValue('total', value?.price); 
          // setValue('quantity', 1);
          setValue('unit_id', value?.default_unit);
          setUnitId(value?.default_unit);
          setValue('edit', 0);
          setValue('itemIndex', -1);
          setValue('product_data', value);
          setValue('unitName', value?.default_unit_name);
          setValue('defaultUnitName', value?.default_unit_name);
          setValue('selected_unit', value?.default_unit);
          setValue('units', value?.units);
          
          
          if (qtyTextFieldRef.current) {
            qtyTextFieldRef.current.focus();
            qtyTextFieldRef.current.select();
          }
          setLoading(false);
      } catch (error) {
          setLoading(false);
          console.log(error);
      }
    }
  };

  
  const pricesHandle = async (value:any) => {
    const priceValue = JSON.parse(value);
    // console.log("price selected value : " + priceValue);
    // console.log(Object.keys(value).length);
    // console.log(priceValue.label);
    setDisabled(false);
    setTimeout(() => {
      if (qtyTextFieldRef.current) {
        qtyTextFieldRef.current.focus();
        qtyTextFieldRef.current.select();
      }    
    }, 100);

  }

  const priceValidationSchema = Yup.object().shape({
      variant_id: Yup.string().required('Supplier is required'),
      purchase_price: Yup.string().required('Purchase price is required'),
      sale_price: Yup.string().required('Sale price is required'),
      alert_limit: Yup.string(),
    })

  const { control:priceControl, handleSubmit:priceSubmit, formState: { errors:priceError }, reset:priceReset, setValue:setPriceValue, getValues:getPriceValues, setError:priceSetError } = useForm({
      resolver: yupResolver(priceValidationSchema),
      defaultValues: {
          variant_id: "",
          purchase_price: "",
          sale_price: "",
          alert_limit: "",
      }
  })

  const handleNewPriceDialogClose = () => {
      setDialogOpen(false);
  };

  const handleOpenDialog = () => {
      if(getValues('product').id != ''){
        setDialogOpen(true);
      }else{
        alert('Please select any product');
      }
  }

  const onpriceSubmit = async (data:any) => {
    setLoading(true);
    try {
        console.log(data);
        
        const resData = await purchaseStore.createPriceData(data)
        if (resData){
          priceReset()
          // console.log(resData);
          
          const object = {id:resData.data.price.id, label:resData.data.price.purchase_price}
          setPrices((prevItems) => [...prevItems, object])
          setValue('price', object);
          handleNewPriceDialogClose();
          
          setDisabled(false);
          setTimeout(() => {
            if (qtyTextFieldRef.current) {
              qtyTextFieldRef.current.focus();
              qtyTextFieldRef.current.select();
            }    
          }, 100);

        }
        setLoading(false);
    } catch (error:any) {
        Object.keys(error?.data).map((e:any) => {
        priceSetError(e, {
            type: 'manual', // Use 'manual' for manually triggered errors
            message: error?.data[e],
        });
        })
        setLoading(false);
    }
}
  
  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Dialog open={dialogOpen} onClose={handleNewPriceDialogClose}>
    <DialogTitle>{t('newPriceDetails')}</DialogTitle>
    <form onSubmit={priceSubmit(onpriceSubmit)}>
    <DialogContent>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={12} md={4}>
        <Controller
        name="purchase_price"
        control={priceControl}
        render={({ field }) => (
            <TextField
            {...field}
            fullWidth
            id="purchase_price"
            label={t('purchasePrice')}
            variant="filled"
            error={!!priceError.purchase_price}
            helperText={priceError.purchase_price?.message}
            autoComplete="off"
            sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    padding: '0 0px', // Input padding
                    fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                },
                }}
            />
        )}
        />
    </Grid>
    <Grid item xs={12} md={4}>
        <Controller
        name="sale_price"
        control={priceControl}
        render={({ field }) => (
            <TextField
            {...field}
            fullWidth
            id="sale_price"
            label={t('salePrice')}
            variant="filled"
            error={!!priceError.sale_price}
            helperText={priceError.sale_price?.message}
            autoComplete="off"
            sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    padding: '0 0px', // Input padding
                    fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                },
                }}
            />
        )}
        />
    </Grid>
    <Grid item xs={12} md={4}>
        <Controller
        name="alert_limit"
        control={priceControl}
        render={({ field }) => (
            <TextField
            {...field}
            fullWidth
            id="alert_limit"
            label={t('alert')}
            variant="filled"
            error={!!priceError.alert_limit}
            helperText={priceError.alert_limit?.message}
            autoComplete="off"
            sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    padding: '0 0px', // Input padding
                    fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                },
                }}
            />
        )}
        />
    </Grid>
    </Grid>
    </DialogContent>
    <DialogActions>
    <Button onClick={handleNewPriceDialogClose}>
        {t('cancel')}
    </Button>
    <Button color="primary" type="submit">
        {t('save')}
    </Button>
    </DialogActions>
    </form>
</Dialog>
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3}} sx={{my:1 }} wrap="wrap">
    <Grid item xs={12} sm={6} md={2.5}>
      <Controller
      key={"product"}
        name="product"
        control={control}
        render={({ field }) => (
          <ServerSideAutocomplete
            label={t('selectProduct')}
            ajaxCallFn={productStore.getListForPurchase} 
            onOptionSelect={(option) => {
              field.onChange(option)
              handleSelectProduct(option)
            }}
            error={errors.product?.id ?? errors.product }
            field={field}
          />
        )}
      />
      
    </Grid>
    <Grid item xs={12} sm={6} md={1.8}>
        {/* Autocomplete Field */}
        <Controller
          name="price"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={prices}
              getOptionLabel={(option) => String(option?.label || "")}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(_, value) => {
                // console.log(value);
                field.onChange(value)
                if(value !== null){
                  pricesHandle(JSON.stringify(value))
                }
              }}
              inputValue={inputPriceValue}
              onInputChange={(event, newInputValue) => setPriceInputValue(newInputValue)}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('price')}
                  variant="filled"
                  fullWidth
                  margin="normal"
                  error={!!errors.price}
                  helperText={errors.price?.message}
                  inputRef={priceTextFieldRef}
                  autoFocus={false}
                  sx={{
                    margin: 0, // Outer margin
                    '& .MuiInputBase-root': {
                      height: '40px', // Input height
                      paddingleft: '8px', // Input padding
                      paddingTop:'8px',
                      fontSize: '15px',
                    },
                    '& .MuiInputLabel-root': {
                      padding: '0',
                      marginTop: '-5px',
                      fontSize: '14px',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                        sx={{
                          padding: 0,          // removes padding
                          margin: '-8px -5px 0px 0px',           // removes margin
                          minWidth: 0,         // removes default width
                          width: 'auto',       // or set a specific width if needed
                        }}
                        onClick={()=>handleOpenDialog()}
                      ><AddIcon fontSize="small"/></Button>
                        {params.InputProps.endAdornment}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}
        />
    </Grid>
    {fields.map((item, index) => {
        // console.log(item.unit_id);
        if(item.unit_id !== 0){
        return (
          <Grid item xs={12} sm={6} md={1.2}>
          <Controller
          name={`product_units.${index}.quantity`}
          control={control}
          render={({ field, fieldState }) => (
              <TextField
              {...field}
              fullWidth
              id={`unit_id-${item.unit_id}`}
              label={item.name}
              variant="filled"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              inputRef={item.unit_id===item.default?qtyTextFieldRef:null}
              onChange={(e)=>{
                field.onChange(e);
                // console.log(e.currentTarget.id.split('-')[1]);
                calculateUnitPrice();
              }}
              sx={{
                  margin: 0, // Outer margin
                  '& .MuiInputBase-root': {
                    height: '40px', // Input height
                    padding: '0 0px', // Input padding
                    fontSize: '14px'
                  },
                  '& .MuiInputLabel-root': {
                    padding: '0',
                    marginTop: '-5px',
                    fontSize: '14px',
                  },
                }}
                disabled={isDisabled}
              />
          )}
          />
          {errors.product_units?.message && (
            <p style={{ color: "red" }}>{errors.product_units.message}</p>
          )}
          </Grid>
        )
        }
      })}
    <Grid item xs={12} sm={6} md={1.2}>
      <Controller
          name="discount"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e);
                calculateUnitPrice()
              }}
              fullWidth
              id="discount"
              label={t('discount_per')}
              variant="filled"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              error={!!errors.discount}
              helperText={errors.discount?.message}
            />
          )}
        />
    </Grid>
    <Grid item xs={12} sm={6} md={1.8}>
      <Controller
          name="total"
          control={control}
          render={({ field }) => (
            <TextField
              InputProps={{
                readOnly: true,
                disabled: true,
                startAdornment: (
                  <InputAdornment position="start">
                    Tk.
                  </InputAdornment>
                ),
              }}
              {...field}
              fullWidth
              id="total"
              label={t('total')}
              variant="filled"
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 14px', // Input padding
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
              error={!!errors.total}
              helperText={errors.total?.message}
            />
          )}
        />
    </Grid>
    <Grid item xs={12} sm={6} md={1}>
        <Button sx={{ mt: 0 }} type="submit" variant="contained" color="secondary">
            {t('add')}
        </Button>
    </Grid>
  </Grid>
   </form>
   </>
  )
}

export default observer(AddNewItemForm)