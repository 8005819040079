import { observer } from "mobx-react-lite";
import React, {useState} from "react";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, TextField, Button, InputAdornment } from '@mui/material';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../../store/rootStore';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


const SupplierCreate = () => {

    const { rootStore: { supplierStore } } = useStore();
    const { createData } = supplierStore;
    const [t, i18n] = useTranslation("global");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('formValidation.name')),
        company: Yup.string().required(t('formValidation.company')),
        address: Yup.string().required(t('formValidation.address')),
        phone: Yup.array().of(
            Yup.object().shape({
              number: Yup.string()
              .matches(/^\d{0}$|^\d{11}$/, "Phone number must be exactly 11 digits or empty")
              .notRequired(),
            })
        ),
        due: Yup.number().typeError(t('formValidation.numberOnly')),
        advance: Yup.number().typeError(t('formValidation.numberOnly')),
        accounts: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Bank name is required"),
              account: Yup.string().required("Bank account required"),
            })
        ),
      })

    type FormValues = Yup.InferType<typeof validationSchema>;

    const { control, handleSubmit, formState: { errors }, reset, setValue, setError } = useForm<FormValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: "",
            company: "",
            address: "",
            phone: [{ number: "" }],
            due: 0,
            advance: 0,
            accounts: [{ name: "", account: ""}],
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "phone", // Name of the field array
    });

    const { fields:bankFields, append:bankAppend, remove:bankRemove } = useFieldArray({
        control,
        name: "accounts", // Name of the field array
    });

    const formatNumberWithCommas = (value:any) => {
        if (!value) return '';
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const removeCommas = (value:any) => {
        return value.replace(/,/g, '');
    };

    const onSubmit = async (data:any) => {

        data.phone = JSON.stringify(data.phone);
        data.accounts = JSON.stringify(data.accounts);

        setLoading(true);
        try {
            const resData = await createData(data)
            if (resData){
            reset()
            navigate('..')
            }
            setLoading(false);
        } catch (error:any) {
            Object.keys(error?.data).map((e:any) => {
            setError(e, {
                type: 'manual', // Use 'manual' for manually triggered errors
                message: error?.data[e],
            });
            })
            setLoading(false);
        }
    }

    return (
        <>
        {loading ? 
        <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
            <LinearProgress color="secondary"/>
        </Box>
        : ''
        }
        
        <Box sx={{ width: '100%' }}>
            <h4>{t('suppliers.create')}</h4>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="name"
                        label={t('name')}
                        variant="filled"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                    name="company"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="company"
                        label={t('company')}
                        variant="filled"
                        error={!!errors.company}
                        helperText={errors.company?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Controller
                    name="due"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        id="due"
                        label={t('due_save')}
                        variant="filled"
                        error={!!errors.due}
                        helperText={errors.due?.message}
                        autoComplete="off"
                        onBlur={onBlur}
                        onFocus={(e) => e.target.select()}
                        value={formatNumberWithCommas(value)}
                        onChange={(e) => {
                            const rawValue = removeCommas(e.target.value);
                            setValue("due", rawValue, { shouldValidate: true });
                        }}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Controller
                    name="advance"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                Tk.
                            </InputAdornment>
                            ),
                        }}
                        fullWidth
                        id="advance"
                        label={t('advance_save')}
                        variant="filled"
                        error={!!errors.advance}
                        helperText={errors.advance?.message}
                        autoComplete="off"
                        onBlur={onBlur}
                        onFocus={(e) => e.target.select()}
                        value={formatNumberWithCommas(value)}
                        onChange={(e) => {
                            const rawValue = removeCommas(e.target.value);
                            setValue("advance", rawValue, { shouldValidate: true });
                        }}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="address"
                        label={t("address")}
                        variant="filled"
                        error={!!errors.address}
                        helperText={errors.address?.message}
                        autoComplete="off"
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12}>
                <h4 style={{padding:0,margin:0}}>{t("backAccount")}</h4>
                </Grid>
                <Grid item xs={12}>
                {bankFields.map((item, index) => (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={5.5}>
                    <Controller
                    name={`accounts.${index}.name`}
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="bankName"
                        label={t("bankName")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                    <Controller
                    name={`accounts.${index}.account`}
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="bank_account"
                        label={t("bankAccountName")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => bankRemove(index)}
                      >
                      <DeleteIcon/>
                      </Button>
                    </Grid>
                </Grid>
                ))}

                <Button
                variant="contained"
                color="primary"
                onClick={() => bankAppend({ name: "", account: ""})}
                sx={{ marginBottom: 2, marginTop: 2 }}
                >
                <AddIcon/>
                </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                {fields.map((item, index) => (
                <Box
                    key={item.id}
                    sx={{
                    display: "flex",
                    gap: 2,
                    marginBottom: 2,
                    alignItems: "center",
                    }}
                >
                    <Controller
                    name={`phone.${index}.number`}
                    control={control}
                    render={({ field, fieldState }) => (
                        <TextField
                        {...field}
                        fullWidth
                        id="phone"
                        label={t("phone")}
                        variant="filled"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{
                            margin: 0, // Outer margin
                            '& .MuiInputBase-root': {
                              height: '40px', // Input height
                              padding: '0 0px', // Input padding
                              fontSize: '14px'
                            },
                            '& .MuiInputLabel-root': {
                              padding: '0',
                              marginTop: '-5px',
                              fontSize: '14px',
                            },
                          }}
                        />
                    )}
                    />
                    <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => remove(index)}
                    >
                    <DeleteIcon/>
                    </Button>
                </Box>
                ))}

                <Button
                variant="contained"
                color="primary"
                onClick={() => append({ number: "" })}
                sx={{ marginBottom: 2 }}
                >
                <AddIcon/>
                </Button>
                </Grid>
                </Grid>
                <Button sx={{ mt: 2 }} variant="contained" onClick={() => navigate(-1)}>
                {t('back')}
                </Button>
                <Button sx={{ mt: 2, ml: 2 }} type="submit" variant="contained" color="success">
                {t('save')}
                </Button>
            </form>
        </Box>
        </>
    )
}

export default observer(SupplierCreate);