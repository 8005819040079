import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Grid, TextField, Toolbar, Typography, useMediaQuery, useTheme, Pagination, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import DatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const PurchaseList = () => {
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");

    const [page, setPage] = useState(1);
        
    const isMobile = useMediaQuery("(max-width:600px)");
    
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm")); // Full screen on small devices
    const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md")); // Medium devices

    const {rootStore: {purchaseStore, authStore}} = useStore();

    const itemsPerPage = 5;
            
    const count = Math.ceil(purchaseStore.rowData.length / itemsPerPage);

    const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        // console.log(value);
        setPage(value);
    };

    
    const paginatedData = purchaseStore.rowData.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    const initTable = async () => {
        setLoading(true);
        try {
            const resData = await purchaseStore.fetchList('null', 'null');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const validationSchema = Yup.object().shape({
      toDate: Yup.date()
      .nullable()
      .required('Date is required')
      .typeError('Invalid date format')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : new Date(originalValue);
      }),
      fromDate: Yup.date()
      .nullable()
      .required('Date is required')
      .typeError('Invalid date format')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : new Date(originalValue);
      }),
    })

    const { control, handleSubmit, formState: { errors }, reset, setError } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
          toDate: new Date(),
          fromDate: new Date(),
        }
    })

    const onSubmit = async (data:any) => {
      setLoading(true);
      try {
          const fromDate = new Date(data.fromDate);
          const formattedFormDate = `${fromDate.getFullYear()}-${String(fromDate.getMonth() + 1).padStart(2, '0')}-${String(fromDate.getDate()).padStart(2, '0')}`;
          const toDate = new Date(data.toDate);
          const formattedToDate = `${toDate.getFullYear()}-${String(toDate.getMonth() + 1).padStart(2, '0')}-${String(toDate.getDate()).padStart(2, '0')}`;
          const resData = await purchaseStore.fetchList(formattedFormDate, formattedToDate);
          // console.log(resData);
          setLoading(false);
      } catch (error:any) {
          Object.keys(error?.data).map((e:any) => {
          setError(e, {
              type: 'manual', // Use 'manual' for manually triggered errors
              message: error?.data[e],
          });
          })
          setLoading(false);
      }
    }

    useEffect(()=>{
        initTable();
    }, []);

    const confirmDelete = async(id:any)=>{
      setLoading(true);
      
      try {
        const resData = await purchaseStore.deleteData(id);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    const deleteData = (id:any) => {

      purchaseStore.rootStore.dialogStore.openDialog({
        confirmFn: () => confirmDelete(id),
        dialogText: t("purchases.deleteConfirmText")
      })
      // customerStore.deleteDialog(params);
      // setLoading(false);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLowerCase();
      setSearchText(event.target.value);

      purchaseStore.rowData = purchaseStore.searchData.filter((row)=>{
        return row.invoice_number.toLowerCase().includes(value)
         || row.supplier_name.toLowerCase().includes(value)
         || row.company.toLowerCase().includes(value)
         || row.formatted_date.toLowerCase().includes(value)
         || row.formatted_price.replace(/,/g, '').includes(value)
         || row.formatted_discount.replace(/,/g, '').includes(value)
         || row.formatted_time.toLowerCase().includes(value);
      })
    };

  return (
    <>
    {loading ? 
    <Box sx={{ width: '100%', position:'fixed',top:'0%',left:'0%', zIndex:'1999' }}>
        <LinearProgress color="secondary"/>
    </Box>
    : ''
    }
    <Box sx={{ height: 400, width: '100%' }}>
      <h4>{t('purchases.list')}</h4>
       <Button variant="contained" sx={{mb:2}} component={Link} to="create"><PersonAddIcon sx={{mr:1}}/>{t('addNew')}</Button>
       <Toolbar style={{padding: 0, justifyContent:'space-between', alignItems:'center'}}>
        <Grid container alignItems="center" justifyContent={'space-between'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={3}>
            <TextField
              variant="outlined"
              label={t('search')}
              value={searchText}
              onChange={handleSearch}
              fullWidth
              autoComplete='off'
              sx={{
                margin: 0, // Outer margin
                '& .MuiInputBase-root': {
                  height: '40px', // Input height
                  padding: '0 0px', // Input padding
                  fontSize: '14px'
                },
                '& .MuiInputLabel-root': {
                  padding: '0',
                  marginTop: '-5px',
                  fontSize: '14px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container alignItems="center" justifyContent={'flex-end'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="fromDate"
                    render={({ field }) => (
                        <DatePicker
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        dateFormat="dd-MM-yyyy"
                        customInput={
                            <TextField
                            variant="outlined"
                            fullWidth
                            sx={{
                                margin: 0, // Outer margin
                                '& .MuiInputBase-root': {
                                height: '40px', // Input height
                                padding: '0 0px', // Input padding
                                fontSize: '14px'
                                },
                            }}
                            />
                        }
                        />
                    )}
                  />
                  {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate.message}</p>}
                </Grid>
                <Grid item xs={1}>
                    To
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    control={control}
                    name="toDate"
                    render={({ field }) => (
                        <DatePicker
                        placeholderText="Select a date"
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                        dateFormat="dd-MM-yyyy"
                        customInput={
                            <TextField
                            variant="outlined"
                            fullWidth
                            sx={{
                                margin: 0, // Outer margin
                                '& .MuiInputBase-root': {
                                height: '40px', // Input height
                                padding: '0 0px', // Input padding
                                fontSize: '14px'
                                },
                            }}
                            />
                        }
                        />
                    )}
                  />
                  {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate.message}</p>}
                </Grid>
                <Grid item xs={2}>
                  <Button sx={{ ml: .01, mr:0 }} type="submit" variant="contained" color="success">
                      {t('search')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Toolbar>
      {isMobile ? 
      <>
      <Grid container alignItems="baseline" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {
          paginatedData.map((info,index)=>(
              <Grid item xs={12}>
                  <Paper elevation={5}
                  sx={{
                      p:1
                  }}>
                  <Grid container alignItems="baseline">
                    <Grid container alignItems="baseline">
                      <Grid item xs={6}>
                            <span style={{fontSize:'16pt'}}>{t('invoiceNumber')} : </span><span style={{fontSize:'12pt'}}>{info.invoice_number}</span>
                      </Grid>
                      <Grid item xs={6}>
                          <Box sx={{ display: "flex", alignItems:'flex-start', gap:'3px', justifyContent:'flex-end' }}>
                              <CalendarMonthIcon fontSize="small"/> <span style={{fontSize:'12pt'}}>{info.formatted_date}</span>
                              <AccessTimeIcon fontSize="small"/> <span style={{fontSize:'12pt'}}>{info.formatted_time}</span>
                          </Box>
                      </Grid>
                      <Grid item xs={6}>
                          <span style={{fontSize:'18pt'}}>{info.supplier_name}</span>
                      </Grid>
                      <Grid item xs={6}>
                          <span style={{fontSize:'18pt'}}>{info.company}</span>
                      </Grid>
                      <Grid item xs={6}>
                          <span style={{fontSize:'16pt'}}>{t('price')} : </span>
                          <span style={{fontSize:'12pt', fontWeight:'bold'}}>{info.formatted_price}</span>
                      </Grid>
                      <Grid item xs={6}>
                          <span style={{fontSize:'16pt'}}>{t('discount')} : </span>
                          <span style={{fontSize:'12pt', fontWeight:'bold'}}>{info.formatted_discount}</span>
                      </Grid>
                      <Grid item xs={6}>
                          <span style={{fontSize:'16pt'}}>{t('netAmount')} : </span>
                          <span style={{fontSize:'12pt', fontWeight:'bold'}}>{info.netAmount}</span>
                      </Grid>
                        <Grid item xs={6}>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'view/'+info.id}><LocalPrintshopIcon/></Button>
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'edit/'+info.id}><EditIcon/></Button>:""}
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(info.id)}><DeleteIcon/></Button>:""}
                        </Grid>
                      </Grid>
                  </Grid>
                  </Paper>
              </Grid>
          ))
      }
      </Grid>
      <Pagination 
      count={count} 
      page={page} 
      onChange={handleChange} 
      color="primary" 
      size={isMobile ? "small" : "medium"} 
      sx={{ mt: 2, display: "flex", justifyContent: "center" }} 
      />
      </>
      :
      <DataGrid
        rows={purchaseStore.rowData}
        columns={
          [
            {
              field: "id",
              headerName: "#",
              filterable: false,
              width: 50,
              renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1
              },
              { field: 'invoice_number', headerName: t('invoiceNumber'), width: 80 },
              { field: 'supplier_name', headerName: t('name'), width: 150 },
              { field: 'company', headerName: t('company'), width: 150 },
              { field: 'formatted_price', headerName: t('price'), width: 100, align:'right', headerAlign:'right' },
              { field: 'formatted_discount', headerName: t('discount'), width: 100, align:'right', headerAlign:'right' },
              { field: 'netAmount', headerName: t('netAmount'), width: 100, align:'right', headerAlign:'right' },
              { field: 'formatted_date', headerName: t('date'), width: 100 },
              { field: 'formatted_time', headerName: t('time'), width: 150 },
              {
                  field: 'actions',
                  headerName: t('action'),
                  width: 150,
                  sortable: false,
                  filterable: false,
                  renderCell: (params) => (
                      <>
                          <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'view/'+params.id}><LocalPrintshopIcon/></Button>
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} component={Link} to={'edit/'+params.id}><EditIcon/></Button>:""}
                          {authStore.role==='Admin' ? <Button style={{maxWidth:'40px', minWidth:'40px'}} onClick={()=>deleteData(params.id)}><DeleteIcon/></Button>:""}
                      </>
                  )
              }
          ]
        }
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 25, 100]}
      />
      }
    </Box>
    </>
  );
}

export default observer(PurchaseList);
