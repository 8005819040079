import * as React from "react";
import { useMediaQuery } from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store/rootStore";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ListItemButton } from "@mui/material";
import { Button } from '@mui/material';
import UpdateItemForm from "./updateItemForm";
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FilledInput from '@mui/material/FilledInput';



const AllItemList:React.FC<any> = ({editMode}) => {

  const [edit, setEdit] = React.useState(0);
  const [editIndex, setEditIndex] = React.useState(null);
  const [discount, setDiscount] = React.useState<any>(0);
  const [payment, setPayment] = React.useState<any>(0);
  const [cartTotal, setCartTotal] = React.useState<any>(0);
  const [cartSubTotal, setSubCartTotal] = React.useState<any>(0);
  const [t, i18n] = useTranslation("global");

  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    rootStore: { saleStore },
  } = useStore();
  const { cartItems, removeFromCart } = saleStore;

  function ccyFormat(num: number) {
    const nFormat = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2});
    return nFormat.format(num);
    // return `${num.toFixed(2)}`;
  }

  function discountNumberFormate(e: any){
    let input = e.target.value.replace(/,/g, ''); // Remove commas for parsing
    let numberOnly = e.target.value.replace(/,/g, '');
    let subTotal = 0;
    if (!isNaN(input) && input !== '') {
      input = parseFloat(input).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      saleStore.cartDiscount = numberOnly;
      saleStore.setcartAditionalInputValues(numberOnly,payment);
      subTotal = saleStore.cartTotal - numberOnly;
      // console.log('Calculation : '+numberOnly);
    } else if (input === '') {
      saleStore.cartDiscount = '';
      saleStore.setcartAditionalInputValues(0,payment);
      subTotal = saleStore.cartTotal;
    }
    // console.log(subTotal);
    saleStore.cartSubTotal = subTotal;
    let paidAmount = (payment !== '') ? payment:0;
    // console.log(paidAmount);
    let cartDue = saleStore.cartSubTotal - paidAmount;

    if(saleStore.customerAdvance > 0){
      let calDue = cartDue - saleStore.customerAdvance;
      if(calDue > 0){
        saleStore.calculateDue = calDue;
      }else{
        saleStore.calculateAdvance = Math.abs(calDue);
      }
    }else{
      let calDue = cartDue + saleStore.customerDue;
      saleStore.calculateDue = calDue;
    }
  }

  function paymentNumberFormate(e: any){
    let input = e.target.value.replace(/,/g, ''); // Remove commas for parsing
    let numberOnly = e.target.value.replace(/,/g, '');
    let dueAmount = 0;
    if (!isNaN(input) && input !== '') {
      input = parseFloat(input).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      setPayment(input);
      saleStore.cartPayment = numberOnly;
      saleStore.setcartAditionalInputValues(discount,numberOnly);
      dueAmount = saleStore.cartSubTotal - numberOnly;
    } else if (input === '') {
      saleStore.cartPayment = '';
      saleStore.setcartAditionalInputValues(discount,0);
      dueAmount = saleStore.cartSubTotal;
    }

    let cartDue = dueAmount;

    if(saleStore.customerAdvance > 0){
      let calDue = cartDue - saleStore.customerAdvance;
      if(calDue > 0){
        saleStore.calculateDue = calDue;
      }else{
        saleStore.calculateAdvance = Math.abs(calDue);
      }
    }else{
      let calDue = cartDue + saleStore.customerDue;
      saleStore.calculateDue = calDue;
    }
  }

  const formatNumberWithCommas = (value:any) => {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  function subtotal(items: readonly any[]) {
    return items.map(({ total }) => total).reduce((sum, i) => sum + i, 0);
  }

  
  function handleEdit(index:any){
    console.log('handleEdit');
    setEdit(1);
    setEditIndex(index);
  }
  
  function removeEdit(index:any){
    console.log('removeEdit');
    setEdit(0);
    setEditIndex(null);
  }

  function calculateDue(){
    
    if(saleStore.customerAdvance > 0){
      let calculateDue = saleStore.cartDue - saleStore.customerAdvance;
      if(calculateDue > 0){
        return calculateDue;
      }
    }
  }

  function calculateAdvance(){
    
    if(saleStore.customerAdvance > 0){
      let calculateAdvance = saleStore.cartDue - saleStore.customerAdvance;
      if(calculateAdvance < 0){
        return calculateAdvance;
      }
    }
  }

  const invoiceSubtotal = subtotal(cartItems);
  const invoiceTotal = invoiceSubtotal;
  // setCartTotal(invoiceTotal);
  // setSubCartTotal(invoiceSubtotal);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: '100%' }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={5}>
              {t('details')}
            </TableCell>
            <TableCell align="right">{t('price')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{padding:'8px 15px' }}>#</TableCell>
            <TableCell sx={{padding:'8px 15px' }}>{t('product')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('price')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('quantity')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('discount_per')}</TableCell>
            <TableCell sx={{padding:'8px 15px' }} align="right">{t('total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartItems.map((row, i) => {
            let itemCount = row.units.length;
            let newUnits:any = [];
            for(var inc=0; inc<itemCount; inc++){
              newUnits.push({id:row.units[inc].id, name:row.units[inc].name, calculate_value:row.units[inc].calculate_value, operator:row.units[inc].operator });
            }

            let quantityCount = row.product_units.length;
            let newQuantity:any = [];
            for(var inc=0; inc<quantityCount; inc++){
              newQuantity.push({id:row.product_units[inc].id, name:row.product_units[inc].name, calculate_value:row.product_units[inc].calculate_value, operator:row.product_units[inc].operator, quantity:row.product_units[inc].quantity });
            }

            return (
              <TableRow key={i}>
                <TableCell sx={{padding:'8px 15px' }}>
                  {
                    editMode && editMode == true ?
                    <>
                    <Button style={{maxWidth:'40px', minWidth:'40px', padding: '0px'}} onClick={()=>removeFromCart(i)}><DeleteIcon/></Button>
                    {/* <Button style={{maxWidth:'40px', minWidth:'40px', padding: '0px'}} onClick={()=>handleEdit(i)}><EditIcon/></Button> */}
                    </>:''
                  }
                </TableCell>
                <TableCell sx={{padding:'8px 15px' }}>
                {row.product.label}
                </TableCell>
                <TableCell sx={{padding:'8px 15px' }} align="right">Tk. {ccyFormat(row.price.label)} {row.defaultUnitName}</TableCell>
                <TableCell sx={{padding:'8px 15px' }} align="right">
                  {
                    row.product_units.map((item:any, index:any)=>{
                      if(item.quantity != ''){
                        return (
                          <>
                          {item.quantity} {item.name}
                          {index < row.product_units.length - 1 && ', '}
                          </>
                          )
                      }
                    })
                  }
                </TableCell>
                <TableCell sx={{padding:'8px 15px' }} align="right">{row.discount}</TableCell>
                <TableCell sx={{padding:'8px 15px' }} align="right">{ccyFormat(row.total)}</TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            {
              !isMobile ? 
              <>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              </>
              :''
            }
            <TableCell colSpan={isMobile?4:0} sx={{padding:'8px 15px' }} align="right">{t('subTotal')}</TableCell>
            <TableCell colSpan={isMobile?2:0} sx={{padding:'8px 15px' }} align="right">{ccyFormat(saleStore.cartTotal)}</TableCell>
          </TableRow>
          <TableRow>
            {
              !isMobile ? 
              <>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              </>
              :''
            }
            <TableCell colSpan={isMobile?4:0} align="right" sx={{padding:'8px 15px' }}>{t('discount')}</TableCell>
            <TableCell colSpan={isMobile?2:0} align="right" sx={{padding:'8px 15px' }}>
              <FormControl sx={{ m: 0, width: '100%', height: '30px'}} variant="filled">
                <FilledInput
                  id="filled-adornment-weight"
                  sx={{
                    padding: '0 5px',
                    textAlign: 'right',
                    '.MuiFilledInput-input': {
                      padding: '0 5px',
                      textAlign: 'right',
                    },
                    height:30
                  }}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  value={formatNumberWithCommas(saleStore.cartDiscount)}
                  onChange={discountNumberFormate}
                  
                />
              </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            {
              !isMobile ? 
              <>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              </>
              :''
            }
            <TableCell colSpan={isMobile?4:0} sx={{padding:'8px 15px' }} align="right">{t('netTotal')}</TableCell>
            <TableCell colSpan={isMobile?2:0} sx={{padding:'8px 15px' }} align="right">{ccyFormat(saleStore.cartSubTotal)}</TableCell>
          </TableRow>
          <TableRow>
            {
              !isMobile ? 
              <>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              </>
              :''
            }
            <TableCell colSpan={isMobile?4:0} align="right" sx={{padding:'8px 15px' }}>{t('payment')}</TableCell>
            <TableCell colSpan={isMobile?2:0} align="right" sx={{padding:'8px 15px' }}>
            <FormControl sx={{ m: 0, width: '100%', height: '30px'}} variant="filled">
              <FilledInput
                id="filled-adornment-weight"
                sx={{
                  padding: '0 5px',
                  textAlign: 'right',
                  '.MuiFilledInput-input': {
                    padding: '0 5px',
                    textAlign: 'right',
                  },
                  height:30
                }}
                inputProps={{
                  autoComplete: 'off',
                }}
                value={formatNumberWithCommas(saleStore.cartPayment)}
                onChange={paymentNumberFormate}
              />
            </FormControl>
            </TableCell>
          </TableRow>
          <TableRow>
            {
              !isMobile ? 
              <>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              </>
              :''
            }
            <TableCell colSpan={isMobile?4:0} sx={{padding:'8px 15px' }} align="right">{t('due')}</TableCell>
            <TableCell colSpan={isMobile?2:0} sx={{padding:'8px 15px' }} align="right">{ccyFormat(saleStore.calculateDue)}</TableCell>
          </TableRow>
          <TableRow>
            {
              !isMobile ? 
              <>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              <TableCell sx={{ border: 'none' }}/>
              </>
              :''
            }
            <TableCell colSpan={isMobile?4:0} sx={{padding:'8px 15px' }} align="right">{t('totalAdvance')}</TableCell>
            <TableCell colSpan={isMobile?2:0} sx={{padding:'8px 15px' }} align="right">{ccyFormat(saleStore.calculateAdvance)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default observer(AllItemList);
